import { useState } from 'react'
import InputEmail from './InputEmail'
import InputPassword from './InputPassword'


const FormLogin = () =>{

	const [value, setValue ] = useState(
	{
		email:"",
		clave:""

	}
		)

	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const submit = async(event) =>{
		event.preventDefault();
		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });
		  			
			if ( 
				//["",undefined,"undefined"].includes(window.getCookie("commerce_lead_token"))
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
		        const formData = new FormData();
		        formData.append('action', 'shopLogin');
		        formData.append('customer[email]', value.email);
		        formData.append('customer[password]', value.clave);

				const response = await fetch(window.path_service+"/user/login",{method:'POST',body:  JSON.stringify(value) })
				const data = await response.json()

				if ( data.hasOwnProperty("status") && data.status ) {
			        //document.cookie='commerce_lead_token='+data.lead_token;
			        localStorage.setItem('token_authlead', data.lead_token );
			        					
			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          });         					
					modaLoading.close();
					window.location.href = "/";
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 3500
				    }); 
				    modaLoading.close();					
				}
			}else{
				window.location.href = "/";
			}
	}	

	return (
				<form className="p-3 mt-3" onSubmit={submit}>
					
					<div className="form-group-flex">

					<InputEmail 
						span = "far fa-user"
					    validated="yes"
					    name="email"
					    value = {value.email}
					    classWrap="form-field d-flex align-items-center"
					    placeholder="Nombre de Usuario"
					    clasInput="form-login-user"
					    onChange = {handleChangeForm}
					    />

					<InputPassword 
						span="fas fa-key"
					    validated="yes"
					    name="clave"
					    placeholder="Contraseña"
					    value = {value.clave}
					    classWrap="form-field d-flex align-items-center"
					    clasInput="form-login-pass"
					    onChange = {handleChangeForm}
					    />

					</div>

					<div className="form-group-flex">

		                   {
						    (
							    value.clave !== "" && 
							    (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value.email) )
							)
		                   	?

		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">INICIAR SESIÓN</button>
		                   </div>

		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">INICIAR SESIÓN</button>
		                   </div>		                   
		                   }
					</div>

				</form>		
	)
}

export default FormLogin;