import { useState } from 'react'

const InputPassword = ({label,span,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{
	const [showPassword,setShowPassword]  = useState(false)

	const showandhidePass = (val) =>{
		if (val === "show") {
			setShowPassword(true)			
		}else{
			setShowPassword(false)
		}
	}
	
	if (rest.value.length === 0 ) {
		classWrap = classWrap +" bad";
	}

	return (
		<div className={classWrap}>
		{
			label != "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}

		{
			span !== "" ?
			<span className={span}></span>
			:null
		}
			
			{
				validated=="yes" ?
					rest.value.length == 0 ?
						showPassword ? 
						<input type="text" className={clasInput+" disabled"} {...rest} id={rest.name} />
						:
						<input type="password" className={clasInput+" disabled"} {...rest} id={rest.name} />
					:
					showPassword ? 
						<input type="text" className={clasInput} {...rest} id={rest.name} />
					:
						<input type="password" className={clasInput} {...rest} id={rest.name} />
				:
				showPassword ? 
				<input type="text" className={clasInput} {...rest} id={rest.name} />
				:
				<input type="password" className={clasInput} {...rest} id={rest.name} />
			}
			{
				showPassword ? 
				<span className="fa fa-eye-slash js-show-pass" onClick={() => showandhidePass("hide")}></span>
				: 
				<span className="fa fa-eye js-show-pass" onClick={() => showandhidePass("show")}></span>
			}
		</div>					  		
	)
}

export default InputPassword;