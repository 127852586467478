const RawPoliticasdecookies = () => {
return(
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-5">Políticas de Cookies</h2>
                        <p>En Cambistape utilizamos cookies para distinguirte de cualquier otro usuario. Esto nos ayuda a brindarte una buena experiencia cuando navegues por nuestra plataforma y, al mismo tiempo, nos permite mejorarla.</p>
                        <strong>¿Qué son?</strong>
                        <p>Las Cookies son pequeños ficheros de información que se almacenan en tu ordenador o dispositivo móvil. Esto es una práctica común en todos los sitios web, y sirven para almacenar información e incluso recuperar aquella ya almacenada.</p>
                        <p>Las cookies no se usan para identificarte personalmente.</p>
                        <strong>¿Qué cookies utilizamos? </strong>
                        <ol>
                            <li>Cookies necesarias Estas cookies son necesarias para permitir que funcionen las características básicas de nuestro sitio y no se pueden desactivar en nuestros sistemas. Usualmente están configuradas para responder a acciones hechas por usted para recibir servicios, tales como ajustar sus preferencias de privacidad, iniciar sesión en el sitio, o llenar formularios. Usted puede configurar su navegador para bloquear o alertar la presencia de estas cookies, pero algunas partes del sitio web no funcionarán. Estas cookies no guardan ninguna información personal identificable.</li>
                            <li>Cookies de rendimiento Estas cookies nos permiten contar las visitas y fuentes de circulación para poder medir y mejorar el desempeño de nuestro sitio. Nos ayudan a saber qué páginas son las más o menos populares, y ver cuántas personas visitan el sitio. Toda la información que recogen estas cookies es agregada y, por lo tanto, anónima. Si no permite estas cookies no sabremos cuándo visitó nuestro sitio, y por lo tanto no podremos saber cuándo lo visitó.</li>
                            <li>Cookies de funcionalidad Estas cookies permiten que el sitio ofrezca una mejor funcionalidad y personalización. Pueden ser establecidas por nosotros o por terceras partes cuyos servicios hemos agregado a nuestras páginas. Si no permite estas cookies algunos de nuestros servicios no funcionarán correctamente. </li>
                            <li>Cookies dirigidas o de publicidad Estas cookies pueden estar en todo el sitio web, colocadas por nuestros socios publicitarios. Estos negocios pueden utilizarlas para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios. No almacenan información personal directamente, sino que se basan en la identificación única de su navegador y dispositivo de acceso al Internet. Si no permite estas cookies, tendrá menos publicidad dirigida. </li>
                            <li>Cookies de redes sociales Estas cookies están configuradas por una serie de servicios de redes sociales que hemos agregado al sitio para permitirle compartir nuestro contenido con sus amigos y redes. Son capaces de hacerle seguimiento a su navegador a través de otros sitios y crear un perfil de sus intereses. Esto podrá modificar el contenido y los mensajes que encuentra en otras páginas web que visita. Si no permite estas cookies no podrá ver o usar estas herramientas para compartir.</li>
                        </ol>
                        <p>Para absolver cualquier duda sobre esta Política comunícate con nosotros a contacto@cambistape.com.</p>
                    </article>
                </div>
    )
}
export default RawPoliticasdecookies;