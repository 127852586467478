import { useState, useEffect } from 'react'

const MapaLeaflet = ({marker = null,vlat=-12.0781462,vlng=-77.03134,showbutonLookup="yes",vmarker="no",polygonDistrito=[],callRequest="no",handleLocation,buscarMiDireccion}) => {
	const [lat, setLat] = useState(vlat)
	const [lng, setLng] = useState(vlng)
	const [zoom, setZoom] = useState(18)
	//const [map, setMap] = useState(null)
	//let fcinitMapID = null;
	const [center, setCenter] = useState(null)

	useEffect(() => {
		window.jsfun||(function(d,s){var z=window.jsfun=function(c){z._.push(c)},$=z.s=d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.setAttribute("charset","utf-8");$.src=window.path_service+"/leaflet/main.js";z.t=+new Date;$.type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script")
		window.cssfun||(function(d,s){var z=window.cssfun=function(c){z._.push(c)},$=z.s=d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.setAttribute("rel","stylesheet");$.href=window.path_service+"/leaflet/main.css";z.t=+new Date;$.type="text/css";e.parentNode.insertBefore($,e)})(document,"link")


		console.log("window.map",window.map)

			if (typeof window.L === "object" && !window.map.hasOwnProperty("_containerId") ) {
				
				/*fcinitMapID =
				setInterval(function(params) {
					window.map = window.L.map('map').setView([lat,lng], zoom );
					clearInterval(fcinitMapID)
				}, 1500);
				*/
				    
				    window.map = window.L.map('map').setView([lat,lng], zoom );
				    window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
				          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				    }).addTo(window.map);

				window.map.on('click', onMapClick);
			}
		        
		  

		if(typeof window.L === "object" && vmarker === "yes"){
			addMarker(vlat, vlng, zoom )
			window.map.invalidateSize()
		}

		if(typeof window.L === "object" && callRequest ==="yes" && polygonDistrito.length > 0){
			//remove old polygon
			if( !["",undefined,"undefined"].includes( window.polygon ) )
				window.map.removeLayer(window.polygon);
			// create a red polygon from an array of LatLng points
			window.polygon = window.L.polygon(polygonDistrito, {color: '#3c78aa'}).addTo(window.map);
			// zoom the map to the polygon
			window.map.fitBounds(window.polygon.getBounds());
		}
		
		//fcmapLookAddress()
	}, 
		(callRequest ==="yes") ? [window.L,polygonDistrito] : [window.L,vlat,vlng]
	);	

	const initMap = () =>{

	}

	const onMapClick =(e) =>{
		addMarker(e.latlng.lat, e.latlng.lng, ["",undefined].includes(window.map._animateToZoom)? zoom : window.map._animateToZoom )
	}

	const addMarker = (vlat,vlng,vzoom) =>{
		handleLocation({lat: vlat,lng: vlng, zoom:vzoom })
		
		window.map.eachLayer((layer) => {
			if(layer['_latlng']!==undefined)
				layer.remove();
		});

		setLat(vlat)
		setLng(vlng)	
		setZoom(vzoom)

		let marker = window.L.marker([vlat,vlng]).addTo(window.map)
			.bindPopup('Esta es tu dirección agregada')
			.openPopup();

		let myFGMarker = new window.L.FeatureGroup();
		myFGMarker.addLayer(marker);
		myFGMarker.addTo(window.map);
		window.map.fitBounds(myFGMarker.getBounds(myFGMarker));
	}


		

	const fcmapLookAddress = async() => {
		const address = buscarMiDireccion()
		let place_id = "";
		if (address !== "") {
			let paramrequest = [];
			paramrequest.push( "search="+encodeURIComponent(address) );
			paramrequest.push( "key=e06bc536-47da-46d7-a795-b12bb1aa1141");
			
			
			const vrequest = paramrequest.join("&")			

			const response = await fetch("https://apis.geodir.co/places/autocomplete/v1/json?"+vrequest,{method:'GET'})
			const data = await response.json()
		
			if ( data.hasOwnProperty("predictions") && data.predictions.length > 0 ) {
				place_id = data.predictions[0].place_id

				getLatLngPlaceId(place_id)
			}
			//
		}
	}



	const getLatLngPlaceId = async(place_id) => {
		const address = buscarMiDireccion()
		
		if (place_id !== "") {
			let paramrequest = [];
			paramrequest.push( "place_id="+encodeURIComponent(place_id) );
			paramrequest.push( "key=e06bc536-47da-46d7-a795-b12bb1aa1141");
			
			const vrequest = paramrequest.join("&")			
			const response = await fetch("https://apis.geodir.co/places/fields/v1/json?"+vrequest,{method:'GET'})
			const data = await response.json()
			if ( 
				data.hasOwnProperty("status") && data.status==="OK" && 
				data.hasOwnProperty("geometry") && data.geometry.hasOwnProperty("coordinates") 
			 ) {
				addMarker(data.geometry.coordinates.lat, data.geometry.coordinates.lon, 18 )
			window.map.setView([data.geometry.coordinates.lat,data.geometry.coordinates.lon])
			}
		}
	}


	return (
		<div className="container-map">
		{
			showbutonLookup === "yes" ?
				<button onClick={() => fcmapLookAddress()} className="btn-search-address"> Encontrar</button>
			:
			null
		}
			
			<div style={{"height": "480px"}} id="map"></div>
		</div>
	)
}

export default MapaLeaflet;