import { useState } from 'react'
import InputEmail from './InputEmail'
import Input from './Input'
import Select from './Select'
import Textarea from './Textarea'



const FormContacto = () =>{
	const [value, setValue ] = useState(
	{
		name:"",
		lastname:"",
		email:"",
		asunto: "",
		motivo: "",
		descripcion:"",
		check1:false,
		check2:false,
		check3:false
	}
		)
console.log(value)

	const handleChangeForm = (e) => {
		setValue((state) => (
		{
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }
	    ))
	}

	const submit = async(event) =>{
		event.preventDefault();
		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Enviando...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });
		  	
		  	const dataq = new FormData();
			const datavalue = Array.from(new FormData(event.target))
			for(let [key, val] of datavalue) {
				dataq.append(key, val );
			}		    
			const response = await fetch(window.path_service+"/api/contactenos",{method:'POST',body:  dataq })
			const data = await response.json()

			if ( data.hasOwnProperty("status") && data.status ) {
				window.Swal.fire({
					icon: 'success',
			        title: data.message,
			        showConfirmButton: false,
			        timer: 4500
			    });         					
			    modaLoading.close();

			}else{
				window.Swal.fire({
					icon: 'error',
				    title: data.message,
				    showConfirmButton: false,
				    timer: 3500
				}); 
				modaLoading.close();					
			}
	}	

	return (
				<form className="js-form-normal" onSubmit={submit}>
					<div className="form-group-flex">

					<Input 
						label = "Nombres*"
					    validated="yes"
					    name="name"
					    value = {value.name}
					    classWrap="form-group"
					    onChange = {handleChangeForm}
					    />
					<Input 
						label = "Apellidos*"
					    validated="yes"
					    name="lastname"
					    value = {value.lastname}
					    classWrap="form-group"
					    onChange = {handleChangeForm}
					    />

					<InputEmail 
						label = "Correo electrónico*"
					    validated="yes"
					    name="email"
					    value = {value.email}
					    classWrap="form-group"
					    clasInput="cb-form-input"
					    onChange = {handleChangeForm}
					    />


						<Select
							value = {value.motivo}
							validated="yes"
							label = "Motivo"
							name="motivo"
							onChange = {handleChangeForm}
						>
							<option value="">Seleccionar </option>
							<option value="1">Atención al cliente</option>
							<option value="2">Proveedor</option>
							<option value="3">Trabaja con nosotros/RRHH</option>
						</Select>

					<Input 
						label = "Asunto*"
					    validated="yes"
					    name="asunto"
					    value = {value.asunto}
					    classWrap="form-group"
					    onChange = {handleChangeForm}
					    />

						<Textarea 
							label = "Descripción*"
						    validated="yes"
						    name="descripcion"
						    value = {value.descripcion}
						    classWrap="form-group"
						    clasInput="cb-form-input"
						    rows="9"
						    style={{"height": "162px"}}
						    placeholder="Detalle aqui." 
						    onChange = {handleChangeForm}
						    />					    

					</div>

					<div className="form-group-flex">

		                   {
						    (
							    value.name !== "" && 
							    value.lastname !== "" && 
							    (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value.email) ) &&
							    value.motivo !== "" && 
							    value.descripcion !== "" && 
							    value.asunto !=="" &&
							    value.check1 &&
							    value.check2 &&
							    value.check3
							)
		                   	?

		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">ENVIAR</button>
		                   </div>

		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">ENVIAR</button>
		                   </div>		                   
		                   }
					</div>

			            <div className="form-check">
			                <input className="form-check-input" type="checkbox" id="terminos-1" 
			                onChange={handleChangeForm} name='check1' checked={value.check1}
			                 />
			                <label htmlFor="terminos-1" className="form-check-label check-label">
			                    <a target="_blank" href="/terminos-y-condiciones">Acepto Términos y Condiciones</a>
			                </label>
			            </div>
			            <div className="form-check mt-3">
			                <input className="form-check-input" type="checkbox" id="politicas-1" 
			                onChange={handleChangeForm} name='check2' checked={value.check2}
			                />
			                <label htmlFor="politicas-1" className="form-check-label check-label">
			                    <a target="_blank" href="/politica-de-privacidad">Acepto Política de Privacidad</a>
			                    <span>y</span>
			                    <a target="_blank" href="/politicas-de-cookies">Política de Cookies</a>
			                </label>
			            </div>
			            <div className="form-check mt-3 mb-4">
			                <input className="form-check-input" type="checkbox" id="tratamiento-1"
			                onChange={handleChangeForm} name='check3' checked={value.check3}
			                />
			                <label htmlFor="tratamiento-1" className="form-check-label check-label">
			                    <a target="_blank" href="/politica-de-privacidad#consentimiento">
			                        Acepto tratamiento de datos personales para fines adicionales a los esenciales del servicio
			                    </a>
			                </label>
			            </div>



				</form>		
	)
}

export default FormContacto;