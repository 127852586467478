import RawTermCondition from "./RawTermCondition"

const Pageterminosycondiciones  = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>
            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm"><a className="links-breadcrumb"
                                                                                  href="/">Inicio</a></li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">Términos y
                                condiciones
                            </li>
                        </ol>
                    </nav>
                </div>

                <RawTermCondition />

            </main>
        </div>
    )
}

export default Pageterminosycondiciones;
