/*
BUSCAR DIRECCIONES
PASO 0
OBTENCION DE ESTE LINK
https://maps.geodir.co/

PASO 1
se llama al servicio con el nombre de la calle y luego se usa obtiene el place_id para poder buscarlo y obtener el lat y lng
const response = await fetch("https://apis.geodir.co/places/autocomplete/v1/json?search=calle%20manuel%20del%20pino%20620&key=e06bc536-47da-46d7-a795-b12bb1aa1141",{method:'GET'})
const data = await response.json()
data.predictions[0].place_id

--------------------------------------

PASO 2
const response = await fetch("https://apis.geodir.co/places/fields/v1/json?place_id=ZTU5YWNhNzktODdmOC01MDhlLTgxZDUtNjMwYWE4NWZlODE5&key=e06bc536-47da-46d7-a795-b12bb1aa1141",{method:'GET'})
const data2 = await response.json()


*/

import { useState, useEffect } from 'react'
import Direccion from './Direccion'
import Distritos from './Distritos'
import Input from './Input'
import Select from './Select'
import Mapa from './Mapa'
import MapaLeaflet from './MapaLeaflet'


const DireccionesShipping = ({litAddress = [] ,handleAddress,callRequest="yes",visual,changeAddressDefaul,changeMethodShopping,expressActive}) => {
	const [direcciones, setDirecciones ] = useState(litAddress)
	const [direccionDefault, setDireccionDefault ] = useState([])
	const [polygonDistrito, setPolygonDistrito ] = useState([])
	const [showList, setShowList ] = useState(true)
	const [getValue, setGetValue] =  useState("tid")
	const [tipoDirecciones] = useState ([
		{ value:"casa" , name:"Casa" },
		{ value:"Departamento" , name:"Departamento" },
		{ value:"Condominio" , name:"Condominio" },
		{ value:"Residencial" , name:"Residencial" },
		{ value:"Oficina" , name:"Oficina" },
		{ value:"Galería" , name:"Galería" },
		{ value:"otro" , name:"Otro" }
	])


	const buscarMiDireccion = () =>{
		let address = ''
	    if (value.addresscalle !=="" && value.addresnumber !=="" && value.distrito !=="" ) {
	    	let disname=value.distritoName
	    	if (value.distritoName === "Cercado de Lima" ) {
	    		disname="Lima"
	    	}
	    	address = value.addresscalle+" "+value.addresnumber+", "+disname
	    }
	    return address
	}

	const getPolygonCity = async (city_id) => {
		console.log("city_id",city_id)
	  	const response = await fetch(window.path_service+"/api/get_polygon_distritos",{method:'POST',body: JSON.stringify({id:city_id})} )	  
	  	const data = await response.json()
	 	
	 	if(data.hasOwnProperty("polygon_arr") && data.polygon_arr !=="" ){
	 		setPolygonDistrito(JSON.parse(data.polygon_arr) )
	 	}
	 	

	};

	function generarRandom(num) {
	    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	    const charactersLength = characters.length;
	    let result = "";
	    let ch;
	    while (result.length < num){
	        ch = characters.charAt(Math.floor(Math.random() * charactersLength));
	        if (!result.includes(ch)){
	            result += ch;
	        }
	    }
	  return result;
	}


	const [value, setValue ] = useState(
	{
		id : generarRandom(6),
		addrestype:"",
		addresscalle:"",
		addresnumber:"",
		addresint:"",
		addresurb:"",
		distrito:"",
		distritoName:"",
		addresref:"",
		default: false,
		lat: -12.09097 , 
		lng: -77.0240016,
		zoom: 18,
		check: false
	}
		)

	const handleLocation = (item) =>{
	    value.lat = item.lat
	    value.lng = item.lng
		setValue((state) => (
		{
	      ...state,
	      lat: item.lat,
	      lng: item.lng
	    }
	    ))	    
	}

	const handleChangeForm = (e) => {
		if (e.target.name === "distrito") {
			value.distritoName = e.target.options[e.target.selectedIndex].text
			getPolygonCity(e.target.value)
		}	    

		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))

	}

	const openModalAddress = () =>{
		window.$("#modalEditAddres").modal("show")
		if (typeof window.map === "object") {
			setTimeout(() => {
				window.map.invalidateSize()
			}, 1000);
		}
	}

	const submit = async(event) =>{
		event.preventDefault();
		if (value.check) {
			
			direcciones.push(value)
			setDirecciones(direcciones);
			handleAddress(direcciones)

					localStorage.createAddress = "yes";
					window.$("#modalEditAddres").modal("hide")

					setValue( 
						{
							/*
							name:"",
							lastname:"",
							phone:"",
							phone2:"",*/
							id : generarRandom(6),
							addrestype:"",
							addresscalle:"",
							addresnumber:"",
							addresint:"",
							addresurb:"",
							distrito:"",
							addresref:"",
							default : false,
							lat: -12.09097 , 
							lng: -77.0240016,
							check: false
						}
					)

			        window.Swal.fire({
			            icon: 'success',
			            title: "Se creo correctamente tu dirección",
			            showConfirmButton: false,
			            timer: 2500
			          });         					
					//getApiData()


				setGetValue("tid")
						
			
		}

	}	

	const handledeleteItem = (item) => {
	  window.Swal.fire({
	    title: 'Estas Seguro de eliminar?',
	    icon: 'warning',
	    showCancelButton: true,
	    cancelButtonColor: "#666",
	    confirmButtonColor: "#13a538",
	    confirmButtonText: 'Si',
	    cancelButtonText: "No"
	  }).then((result) => {
	    if (result.isConfirmed) {
	      deleteItem(item)
	    }
	  })
	}


	const handleTypeMethod = async(target) => {
		if ( ["express","recojotienda"].includes(target) ) {
			const dataq = new FormData();
			dataq.append("id_distrito", localStorage.distritoPay );
			const response = await fetch(window.path_service+"/api/get_distrito_express",{method:'POST',body:  dataq })
			const data = await response.json()
			
			if ( 
				expressActive &&
				data.hasOwnProperty("success") && data.success && 
				data.hasOwnProperty("express") && 
				data.express
			 ) {
			 localStorage.comercioId = data.id_almacen_origen;
			}else{
				alert("El horario del servicio Express es de 9am a 11pm en este distrito");
				target = "schedule";
			}

		}


		changeMethodShopping(target)
	}


	const handleChangeDefault = async (item) => {
		direcciones.forEach(function(val,index){
			val.default = false
			if(val.id === item.id)
				val.default = true 
			direcciones[index] = val;
		});
		setDirecciones(direcciones)
		handleAddress(direcciones)	
	};


	const deleteItem = async (item) =>{
		let addressSafe = direcciones.filter(function(val) {
		    return val.id != item.id
		})		
		setDirecciones(addressSafe)
		handleAddress(addressSafe)
	  	window.Swal.fire({
	          icon: 'success',
	          title: "Se pre elimino correctamente para que los cambios se han efecto guarde los cambios",
	          showConfirmButton: false,
	          timer: 2500
	    });   

	}

	const getApiData = async () => {

		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/customer/user-list-address",{method:'POST',body: JSON.stringify({id:window.detailpath}), headers: myHeaders})
				const data = await response.json()
		       
			//value.distrito = localStorage.distritoPay;

			if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") && data.data.length > 0 ) {
				setDirecciones(data.data);
				handleAddress(data.data)			  	
			}
        }
	};

	const handleShowAll = () =>{
		setShowList(true)
	}

	const handleHideAll = () =>{
		setShowList(false)
		if(direccionDefault.length > 0){
			if(typeof changeAddressDefaul === 'function'){
				changeAddressDefaul(direccionDefault[0])
			}
		}
		
	}

	const handleCloseModal = () =>{
		window.$("#modalEditAddres").modal("hide")
	}

	useEffect(() => {
		if (callRequest ==="no") {
			setDirecciones(litAddress)
		}else{
			getApiData()
		}
		
	}, (callRequest ==="no") ? [litAddress] : [] );
	
	/*
	useEffect(() => {
		if (callRequest ==="no") {
			setDirecciones(litAddress)
		}else{
			getApiData()
		}
		
	}, litAddress);

	console.log(callRequest,litAddress)
	*/

	return(
		

            <div className="fm-container-block">
            	{
            		visual === "payment" && showList===false ?
	            	<div>
		            	<div className="fm-block-button">
		            		<button className="btn-add-item" onClick={handleShowAll}>Cambiar de dirección</button>
		            	</div>
	            	
		            	<ul className="fm-block-list">
		            	{
		            		direccionDefault.map((direccion,direccionkey) =>
		            			<Direccion 
		            			key ={direccionkey}
		            			direccion={direccion}
		            			handledeleteItem= {handledeleteItem}
		            			handleChangeDefault= {handleChangeDefault}
		            			/>
		            			)
		            	}
		            	</ul>            		
	            	</div>
	            	: null
            	}
            	        
            	
            	{
            		showList ?
	            	<ul className="fm-block-list">
	            	{
	            		direcciones.map((direccion,direccionkey) =>
	            			<Direccion 
	            			key ={direccionkey}
	            			direccion={direccion}
	            			handledeleteItem= {handledeleteItem}
	            			handleChangeDefault= {handleChangeDefault}
	            			/>
	            			)
	            	}
	            	</ul>
            		: null
            	}

            	{
            		showList ?
	            	<div className="fm-block-button">
	            		<button className="btn-add-item" onClick={openModalAddress}>Agregar nueva dirección</button>
	            	</div>
	            	: null
            	}

            	{
            		visual === "payment" && showList ?
            		<div>
            		<span className="ms-note">* Tienes que presionar aceptar para ver los horarios disponibles.</span>
            		<div className="ms-item-form">
            			<button className="ms-btn confirm js-btn-change-address" onClick={handleHideAll}>Aceptar</button>
            		</div>            	
            		</div>
            	: null
            	}



		  <div className="modal fade" id="modalEditAddres">
		    <div className="modal-dialog" role="document">
		      <div className="modal-content">
		        <div className="modal-header">
		          <h3 className="ms-form-title">
		            <i className="fa fa-truck" aria-hidden="true"></i> Dirección de envío
		          </h3>
		          <button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
		          <i className="fa fa-times"></i>
		          </button>
		        </div>
		        <div className="modal-body">
		         <form className="form-address" onSubmit={submit}>
		             <div className="ms-wizard-item">
		               <div className="ms-form-description">
		                 <p><strong>Ingresa tu dirección</strong></p>
		               </div>
		               <div className="ms-form-payment-register">

		               {/*
					      <Input 
					        label = "nombre"
					        validated="yes"
					        name="name"
					        value = {value.name}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "apellidos"
					        validated="yes"
					        name="lastname"
					        value = {value.lastname}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "phone"
					        validated="yes"
					        name="phone"
					        value = {value.phone}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "Otro Teléfono (opcional)"
					        validated="no"
					        name="phone2"
					        value = {value.phone2}
					        onChange = {handleChangeForm}
					        />
					        */}

					        <Distritos 
						        label = "Distrito"
						        validated="yes"
						        name="distrito"
						        value = {value.distrito}
						        getValue = {getValue}
						        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "Calle/Avenida/Jirón"
					        validated="yes"
					        name="addresscalle"
					        value = {value.addresscalle}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "Nro/Lote"
					        validated="yes"
					        name="addresnumber"
					        value = {value.addresnumber}
					        onChange = {handleChangeForm}
					        />

					        <Select
						        label = "Tipo de Dirección"
						        validated="yes"
						        name="addrestype"
						        value = {value.addrestype}
						        onChange = {handleChangeForm}

					        >
					        <option value="">Seleccione un Tipo de Dirección</option>
					        {
					        	tipoDirecciones.map(type =>
					        		<option key={type.value} value={type.value}>{type.name}</option>
					        	)
					        }
					        </Select>

					      <Input 
					        label = "Depto./Int (opcional)"
					        validated="no"
					        name="addresint"
					        value = {value.addresint}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "Urbanización (opcional)"
					        validated="no"
					        name="addresurb"
					        value = {value.addresurb}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "Referencia (opcional)"
					        validated="no"
					        name="addresref"
					        value = {value.addresref}
					        onChange = {handleChangeForm}
					        />

		                     <div className="ms-wrap-checkbox">
		                       <div className="ms-flex-address">
		                         <input type="checkbox" id="address-default" className="" 
		                         onChange={handleChangeForm}
		                         name='default'
		                         checked={value.default}
		                         />
		                         <label htmlFor="address-default">Dirección Principal</label>
		                       </div>
		                     </div>


		                 <div className="ms-item ms-full">              
		                   <label htmlFor="mapGoogle">Haz click en tu ubición exacta o en el boton para buscar por tu dirección.</label>
		                   <MapaLeaflet
		                   callRequest={"yes"}
		                   polygonDistrito = {polygonDistrito} 
		                   handleLocation = {handleLocation} 
		                   buscarMiDireccion = {buscarMiDireccion}
		                    />

		                     <div className="ms-wrap-checkbox">
		                       <div className="ms-flex-address">
		                         <input type="checkbox" id="recovery-term-privacidad" className="js-contact-accep-term-privacidad" 
		                         onChange={handleChangeForm}
		                         name='check'
		                         checked={value.check}
		                         />
		                         <label htmlFor="recovery-term-privacidad">Acepto Políticas de privacidad y Políticas de Cookies</label>
		                       </div>
		                     </div>

		                   {
						    (
							    value.typeAdrees !== "" && 
							    value.distrito !== "" && 
							    value.calle !== "" && 
							    value.numlote !== "" &&
							    value.check 
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Dirección</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Dirección</button>
		                   </div>		                   
		                   }
		                 </div>

		               </div>
		             </div>
		         
		          
		          </form>

		        </div>
		      </div>
		    </div>
		  </div>


            </div>	
	)	
}

export default DireccionesShipping;