import FormChangePassword from './FormChangePassword'
import MenuMyAcount from '../components/MenuMyAcount'

const PageCambiarClave = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	return (

		<div id="ms-profile">
		  <div className="ms-section">
		    <div className="ms-tab-profile ms-payment">
		      <div className="ms-lateral-a">
		        <div className="ms-header-menu-profile">
		          <button className="ms-active-menu">Mis Datos Personales</button>
		        </div>
		        <div className="ms-body-menu-profile">
		          <div className="ms-list-menu-profile">
		            <div className="ms-header-list-menu">Mi cuenta</div>
		            <MenuMyAcount active={"cambiar-clave"} />
		          </div>
		        </div>
		      </div>
		      <div className="ms-lateral-b">
		        <div className="ms-body-menu-profile">
		          <div className="ms-info-menu-selected">
		            <div className="ms-title">Cambiar mi clave</div>
		            <div className="ms-form-payment-register">
		              <FormChangePassword/>
		            </div>
		            <div className="ms-separate"></div>

		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>	
	)
}
export default PageCambiarClave;