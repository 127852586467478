const Footer = () =>{

	return(
		<footer className="shop-footer" style={{"backgroundColor": "#344243"}}>
			<div className="col-md-2 shop-footer-align">
				<div className="shop-logo">
					<img src="//cambistape.com/images/logo-blank.svg" />
				</div>
			</div>

			<div className="col-md-3 shop-footer-align">
				<ul className="shop-footer-contact">
					<li>
						<h4>ESCRIBENOS</h4>
					</li>
					<li> {global.config.account.phone} (solo mensajeria de texto)</li>
					<li><a href="/contacto">contacto@cambistape.com</a></li>
				</ul>		
			</div>

			<div className="col-md-2 shop-footer-align">
				<ul className="shop-footer-sitemap">
					<li>
						<h4>MAPA DEL SITIO</h4>
						</li><li><a href="/quienes-somos">Quienes somos</a></li>
						<li><a href="/preguntas-frecuentes">Preguntas frecuentes</a></li>
						<li><a href="/contacto">Contacto</a></li>
						<li><a href="/terminos-y-condiciones">Terminos y condiciones</a></li>
						<li><a href="/politica-de-privacidad">Politica de Privacidad</a></li>
						<li><a href="/politicas-de-cookies">Politica de cookies</a></li>
						<li><a href="/libro-de-reclamaciones">Libro de Reclamaciones</a></li>
					
				</ul>
			</div>

			<div className="col-md-2 shop-footer-align">
				<ul className="shop-footer-social">
					<li>
						<h4>REDES SOCIALES</h4>
					</li>
					<li>
						<ul className="social-icons">
							<li className="social-item"><a href="https://www.facebook.com/cambistape" target="_blank" className="fa fa-facebook-official"></a></li>
							<li className="social-item"><a href="https://www.instagram.com/cambistape" target="_blank" className="fa fa-instagram"></a></li>		
						</ul>
					</li>
				</ul>
			</div>

			<div className="col-md-3 shop-footer-align">
				<ul className="shop-footer-copy">
					<li>
						<p>© Copyright {(new Date().getFullYear())} <a href={global.config.account.website} target="_blank">{global.config.account.name}</a></p>
					</li>
					<li>Todos los derechos reservados</li>
				</ul>
			</div>

		</footer>

	)
}
export default Footer;