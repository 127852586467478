import { useEffect } from 'react'
import React from 'react';
import FormRecoverPassword from "./FormRecoverPassword";
import Header from './Header'
import Footer from './Footer'

const PageRecoverPassword = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	
	useEffect(() => {
      	localStorage.clear()
    	window.$("body").addClass("page-login")
	}, []);


    return (
<div className="wrapper">
        <div className="logo">
            <img src="-png-logo-0.png" alt="" />
        </div>
        <div className="text-center mt-4 name">
            Recuperar Contraseña
        </div>
        <FormRecoverPassword />

        <div className="text-center fs-6">
            <a href="/login">Iniciar sesión?</a>
        </div>
    </div>
  
    );
};

export default PageRecoverPassword;
