const MenuMyAcount = ({active="mi-perfil"}) =>{
	return(
		<ul className="ms-menu-profile">
			<li>
				<button className="ms-item"><i className="fa fa-user-o" aria-hidden="true"></i>Mi perfil</button>
				<ul className="ms-submenu-profile">
				{
					active === "mi-perfil" ?
						<li><a className="active">Mis datos personales</a></li>
					:
						<li><a href="/mi-perfil">Mis datos personales</a></li>
				}

				{
					active === "cambiar-clave" ?
						<li><a className="active">Cambiar mi clave</a></li>
					:
						<li><a href="/cambiar-clave">Cambiar mi clave</a></li>
				}

				{
					active === "mis-cuentas" ?
						<li><a className="active">Mis Cuentas Bancarias</a></li>
					:
						<li><a href="/mis-cuentas">Mis Cuentas Bancarias</a></li>
				}

				</ul>
			</li>

			<li>
				<button className="ms-item"><i className="fa fa-truck fa-flip-horizontal" aria-hidden="true"></i>Pedidos</button>
				<ul className="ms-submenu-profile">
					
					{
						active === "mis-pedidos" ?
							<li><a className="active">Mis pedidos</a></li>
						:
							<li><a href="/mis-pedidos">Mis pedidos</a></li>
					}					
				</ul>
			</li>
		</ul>
	)
}
export default MenuMyAcount;
