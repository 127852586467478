const Pagequienessomos = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>
            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm">
                                <a className="links-breadcrumb" href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                Quienes somos
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-2">Quienes somos</h2>
                        
                        <p>
                            Somos una casa de cambio online con razon social <b>CAMBISTAPE S.A.C</b> identificada con número de ruc <b>20612512346</b>. Trabajamos bajo las normativas del estado por lo cual contamos con Registro y/o permiso de SBS identicada con ----- . Funcionamos a través de la modalidad de transferencia bancaria. Compramos y vendemos dolares americanos, Nuevos soles,  Euros. 
                        </p>
                        <hr/>
                        <p className="color-orange"><b>Nuestro servicio</b></p>
                        <p>
                            Contamos con el servicio más flexible y didactico, asi mismo brindamos la mejor tasa de conversión del mercado
                        </p>
                        <hr/>
                        <p className="color-orange"><b>Por qué somos diferentes de otras casas de cambio</b></p>
                        <ol type="1">
                            <li>
                                Contamos con un equipo especializado en analizar y brindar la mejor tasa de conversión del mercado asi mismo damos facilidad, apoyo y soporte para que puedas culminar tu pedido.
                            </li>
                            <li>
                                Tenemos una opción para trabajar de la mano con empresas con el fin de agilizar las transferencias.
                            </li>                            
                            <li>
                                Contamos con un equipo muy amable en nuestra área de <span className="color-green"> Atención al Cliente</span>,
                                quienes son personas muy atentas que te ayudarán en lo que necesites. Para nosotros,
                                eres una prioridad.
                            </li>
                            <li>
                                Tenemos una web que es fácil, amigable e intuitiva, para que puedas hacer tus pedidos cuando y desde donde quieras
                            </li>
                            <li>
                                Paga a través de tu banca movil para contar con mayor seguridad.
                            </li>
                            <li>
                                Pide desde las <span className="color-green"> 9:00 a.m.</span> <span className="color-green"> hasta las 6:30 p.m.</span> Ampliamos
                            </li>
                        </ol>
                    
                    </article>
                </div>
            </main>            
        </div>
    )
}

export default Pagequienessomos;
