import { useState, useEffect } from 'react'
import MenuMyAcount from '../components/MenuMyAcount'

const PageMisPedidos = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	const [misPedidos, setMisPedidos ] = useState([])
	const [detallePedido, setDetallePedido ] = useState([])
	const [checkeds, setCheckeds] = useState([]);

	const cerrarModal =() =>{
		window.$("#modalListOrdenPedido").modal("hide");
		setCheckeds([])
		setDetallePedido([])
	}

	const getApiData = async () => {
		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
			var myHeaders = new Headers();
		    myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
		    myHeaders.append("Content-Type", "application/json");            

			const response = await fetch(window.path_service+"/user/mispedidos",{method:'POST',headers: myHeaders})	  
			const data = await response.json()
			if ( data.hasOwnProperty("success") && data.success && data.hasOwnProperty("data") && data.data.length > 0 ) {
				setMisPedidos(data.data)
			}
		}
	};

	const handleOpenDescription = (pedido) => {
		getDetallepedido(pedido);
	}

	const getDetallepedido = async(pedido,showModal=true) => {
		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );				

		    const dataq = new FormData();
		    dataq.append("id_pedido", pedido.order_id );		
			const response = await fetch(window.path_service+"/api/pedido/view",{method:'POST',body:  dataq, headers: myHeaders})	  
			const data = await response.json()
			if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") && data.data.length > 0 ) {
				setDetallePedido(data.data)
				if (showModal) {
					window.$("#modalListOrdenPedido").modal("show")
				}else{
					fc_addProductCart(data.data)
				}
			}
		}
	}

	const handleChangeForm = (detalle) => {
		let arr = checkeds
		if ( !checkeds.includes( detalle) ) {
			arr.push(detalle)
		}else{
		    let filtered = checkeds.filter(function(value){ 
		        return value.sku !==detalle.sku;
		    });			
		    arr = filtered
		}

		setCheckeds(arr)
	}

	const repeatAllPedido = (pedido) => {
		getDetallepedido(pedido,false);
	}

	const fc_addProductCart = async(list) => {
		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );				

			let modaLoading;
			modaLoading = 
			  window.Swal.fire({
			    imageUrl: window.path_service+'/images/loading.png',
			    title: 'Cargando datos...',
			    showConfirmButton: false,
			    customClass: 'ms-loading-modal',
			      allowOutsideClick: false
			  });

		    const dataq = new FormData();
		    dataq.append("skus", JSON.stringify(list) );		
		    const response = await fetch(window.path_service+"/api/pedido/add_product",{method:'POST',body:  dataq, headers: myHeaders})	  
		    const data = await response.json()
		    modaLoading.close();

			if ( data.hasOwnProperty("success") && data.success ) {
				        window.Swal.fire({
				            icon: 'success',
				            title: data.message,
				            showConfirmButton: false,
				            timer: 2500
				          }); 		
			  	window.$("#modalListOrdenPedido").modal("hide")
				setCheckeds([]);
				setDetallePedido([]);
				myListCart();
			}else{
					  	window.Swal.fire({
					          icon: 'error',
					          title: "Surgio un error inesperado, intentalo nuevamente",
					          showConfirmButton: false,
					          timer: 2500
					    }); 			
			}

		}
	}

	const handleAddAll = () => {
		fc_addProductCart(detallePedido)
	}	

	const handleAddSelecc = () => {
		fc_addProductCart(checkeds)
	}	

	useEffect(() => {
		getApiData()
	}, []);


	return (
		<div>

	<main  className="wrap-content page-interna" >
		<div className="shop-container-register">
			<div id="ms-profile">
			  <div className="ms-section">
			    <div className="ms-tab-profile ms-payment">
			      <div className="ms-lateral-a">
			        <div className="ms-header-menu-profile">
			          <button className="ms-active-menu">Mis Datos Personales</button>
			        </div>
			        <div className="ms-body-menu-profile">
			          <div className="ms-list-menu-profile">
			            <div className="ms-header-list-menu">Mi cuenta</div>
			            	<MenuMyAcount active={"mis-pedidos"} />
			          </div>
			        </div>
			      </div>
			      
			<div className="ms-lateral-b">
			        <div className="ms-body-menu-profile">
			          <div className="ms-info-menu-selected">
			            <div className="ms-title">Mis pedidos</div>
			            
									<div className="tabla-pedidos">
									   <ul className="header-pedidos">
									      <li className="order">Orden</li>
									      <li className="nombre oculto">Nombre</li>
									      <li className="fecha">Fecha del pedido</li>
									      <li className="fecha">Fecha despacho</li>
									      <li className="total">Total</li>
									      <li className="acciones">Acciones</li>
									   </ul>
									   <ul className="main-pedidos">
			                  	{
			                  		misPedidos.map( pedido => 
									      <li key = {pedido.order_id} className="fila-pedido">
									         <div className="info-columna">
									            <p>{pedido.order_id}</p>
									         </div>
									         <div className="info-columna oculto">
									            <p>Orden #{pedido.order_id}</p>
									         </div>
									         <div className="info-columna">
									            <p>{pedido.fecha_pedido}</p>
									            <span>{pedido.fecha_pedido}</span>
									         </div>
									         <div className="info-columna">
									            <p>{pedido.fecha_despacho}</p>
									            <span>{pedido.fecha_despacho}</span>
									         </div>
									         <div className="info-columna">
									            <p>s/. {pedido.precio_total}</p>
									         </div>
									         <div className="info-columna">
									            <div className="ver" onClick={() => handleOpenDescription(pedido)}><span>Ver</span></div>
									            <div onClick={() => repeatAllPedido(pedido)} className="reordenar"><span>Repetir pedido</span></div>
									         </div>
									      </li>				                      
			                  		)
			                  	}
									   </ul>
									</div>	


			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		</div>


	<div className="modal fade" id="modalListOrdenPedido" tabIndex="-1" role="dialog" aria-labelledby="modalListOrdenPedido">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">
	      <div className="modal-header">
	        <h3 className="ms-form-title">Mi Pedido</h3>
	        <button type="button" onClick={cerrarModal} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
	      </div>



			<div className="container-tabla">
			   <div className="campo-detalle">
				   <ul className="lista-detalle head">
				      <li className="fila-detalle">
				         <div className="item-detalle1"><span>SKU</span></div>
				         <div className="item-detalle2"></div>
				         <div className="item-detalle3">
				            <span>PRODUCTO</span>
				         </div>
				         <div className="item-detalle4"><span>PRECIO</span></div>
				         <div className="item-detalle5"><span>CANTIDAD</span></div>
				         <div className="item-detalle6"><span></span></div>
				         <div className="item-detalle7"><span>TOTAL</span></div>
				         <div className="item-detalle8"></div>
				      </li>
				   </ul>			      
			   </div>
			   <ul className="lista-detalle">
			   {
			   	detallePedido.map(
			   		(detalle,index) =>
				    <li key = {index}  className="fila-detalle">
				         <div className="item-detalle1"><span>{detalle.sku}</span></div>
				         <div className="item-detalle2"></div>
				         <div className="item-detalle3"><p>{detalle.producto}</p></div>
				         <div className="item-detalle4"><span>s/.{detalle.precio_unitario}</span></div>
				         <div className="item-detalle5"><span>{detalle.cantidad}</span></div>
				         <div className="item-detalle6"><span></span></div>
				         <div className="item-detalle7"><span>s/.{detalle.total}</span></div>
				         <div className="item-detalle8"><input onClick={() => handleChangeForm(detalle)} type="checkbox" name={detalle.sku} id={detalle.sku} value={detalle.sku} className="check-fresh" /><label htmlFor={detalle.sku} className="check-label"></label></div>
				    </li>

			   	)
			   }
			   </ul>
			</div>
			<div className="content-buttons">
			   <div className="content-but">
			   		<div onClick={handleAddSelecc } className="button-agregar"><button type="button" className="btn-form1 btn-select">Agregar seleccionados</button></div>
			   		<div onClick={handleAddAll } className="button-agregar"><button type="button" className="btn-form1">Agregar todos</button></div>
			   </div>
			</div>



	    </div>
	  </div>
	</div>

	</main>

</div>
	)
}
export default PageMisPedidos;