const RawTermCondition = () => {
return(
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">

<h2>TÉRMINOS Y CONDICIONES DE CAMBISTAPE S.A.C.<strong> </strong></h2>
Al registrarte y hacer uso de nuestros servicios, aceptas todas las condiciones de este Acuerdo. Revisa esta información importante detalladamente antes de usar nuestros servicios.

Cambistape se reserva el derecho a modificar sus Términos y Condiciones de forma unilateral mediante la publicación de una nueva versión en el sitio web de la Empresa. De modificarse en aspectos sustanciales, Cambistape te notificará sobre ello por correo electrónico.
<h1>¿Quiénes somos?</h1>
Cambistape es una plataforma de cambio de divisas en línea, con domicilio fiscal en Av. Alfredo Mendiola 1201 oficina E-308

Estamos inscritos en el <a href="https://www.sbs.gob.pe/supervisados-y-registros/registros/otros-registros/casas-de-cambio-prestamos-y-empenos/casas-de-cambio">Registro de Empresas y Personas que efectúan Operaciones</a> <a href="https://www.sbs.gob.pe/supervisados-y-registros/registros/otros-registros/casas-de-cambio-prestamos-y-empenos/casas-de-cambio">Financieras o de Cambio de Moneda.</a>

&nbsp;

<strong>USO DE NUESTRA PLATAFORMA</strong><strong> </strong>
<h1>¿Qué obligaciones tengo como usuario?</h1>
Para hacer uso de nuestros servicios, debes ser mayor de 18 años de edad y tener una cuenta bancaria en Soles (S/) y/o en Dólares (USD) en un banco en el Perú. La cuenta registrada en Cambistape será de uso personal e intransferible.

En caso te pidamos información adicional a fin de verificar tu identidad, deberás cumplir con brindarnos dicha información y asegurarte que los datos que nos proporciones sean correctos y actuales.

Cambistape podría rechazar a cualquier nuevo usuario o transferencia a discreción y sin expresión de causa ante la sospecha de que se estén utilizando nuestras plataformas para realizar operaciones fraudulentas y/o delictivas.

De igual manera, Cambistape se encuentra facultado para dar de baja cuentas a discreción, ante la sospecha de que estén siendo empleadas para la ejecución de operaciones fraudulentas y/o actos delictivos.

Una vez que Cambistape haya realizado la transacción, ésta no podrá revertirse y la Empresa no se responsabilizará en ningún caso por cualquier daño o pérdida sufrida por el usuario como consecuencia de la negligencia de éste al momento de ingresar los datos que solicitamos.

El usuario se obliga a no utilizar los servicios de Cambistape para cualquier fin o actividad ilegal. En ese sentido, Cambistape se reserva el derecho a investigar y/o reportar cualquier actividad sospechosa o ilegal a la autoridad pertinente, y brindarle cualquier información que requieran, incluyendo los datos personales del usuario. De igual forma, Cambistape podrá dar seguimiento a aquellos casos que tengan lugar como consecuencia de reclamos o de reportes de violaciones de los términos y condiciones.

Como usuario, te comprometes a observar y respetar los parámetros del servicio, evitando utilizar una cuenta bancaria distinta a la registrada, ingresar información falsa u otros actos similares que afecten directa o indirectamente la calidad del servicio brindado por Cambistape.
<h2>Usuarios y Perfiles:<strong> </strong></h2>
Cada usuario creado en Cambistape, podrá crear un perfil de persona natural y varios perfiles de personas jurídicas. Los perfiles permitirán tener un mayor orden contable del distinto tipo de personas a las que el usuario representa.

En caso de que detectemos que un mismo usuario tiene dos cuentas (duplicidad de cuentas), Cambistape podría fusionarlas, sin necesidad de avisar al titular de las cuentas.
<h1>¿Cómo creo una cuenta y verifico mi identidad?</h1>
<strong> </strong>Para poder operar a través de Cambistape, debes crear una cuenta en la plataforma a través de nuestros aplicativos en IOS o Android, o página web, completando la información requerida.

Durante el proceso de creación de la cuenta, Cambistape te pedirá validar tu identidad a través de su sistema de verificación biométrica. Puedes encontrar más información sobre el proceso de validación de identidad haciendo click <a href="https://www.youtube.com/watch?v=Ic0L0sHU5IM">aquí</a>, así como información relativa al tratamiento de tus datos personales en nuestra Política de Privacidad de datos.

Al hacer uso de los servicios de Cambistape, declaras y reconoces que has brindado datos ciertos y actualizados, y asumes la obligación de completar todos los procedimientos que forman parte del proceso de creación de la cuenta.
<h1>¿Cómo realizo una operación?</h1>
<strong> </strong>Debes enviar tus órdenes de compra desde la página web o a través de la aplicación móvil; para ello deberás brindar la siguiente información:
<ul>
    <li>Número de la cuenta bancaria en Soles o Dólares de destino (<strong>debe ser de titularidad del mismo usuario</strong>).</li>
    <li>Número de tu documento de identidad (DNI, E., RUC, pasaporte).</li>
    <li>Monto a cambiar y</li>
</ul>
Es importante señalar que la operación sólo será procesada y completada una vez que se haya confirmado y validado el abono de los fondos a las cuentas de Cambistape. La corroboración se hará con la recepción del número de operación ingresado por el usuario en la web o aplicativo.
<h2>Recuerda que Cambistape no tiene y no te pedirá acceso a tus cuentas bancarias, por lo que para poder realizar tu operación de cambio deberás realizar la transferencia del monto que desees cambiar desde tu aplicativo de banca móvil.</h2>
<strong> </strong>Asimismo, es responsabilidad del usuario enviar los fondos durante el horario de atención de la Empresa a fin de asegurar el procesamiento de la orden de compra. <strong>Dicho horario de atención es de lunes a viernes de 9:00 a 19:00, y sábados de 9:00 a 14:00.</strong><strong> </strong>
<h2>¿Cómo reviso mis transacciones?</h2>
<strong> </strong>Puedes acceder al detalle de tus operaciones realizadas y otra información relacionada a tu cuenta, dando click en la pestaña “Historial de operaciones”, ingresado a su cuenta personal.
<h2>¿Puedo cancelar mi operación?</h2>
<strong> </strong>Sí. Puedes cancelar tu operación sin costo alguno en cualquier momento siempre y cuando no hayas transferido los fondos a las cuentas de Cambistape. Una vez realizada la transferencia, podrás pedir la cancelación de tu operación siempre y cuando ésta aún no haya sido procesada y no se haya abonado el monto a tu cuenta; de lo contrario quedarás sujeto a asumir los gastos generados.
<h1>¿Cuánto tiempo toma realizar una operación?</h1>
<strong> </strong>Todas las solicitudes de operación recibidas dentro del horario de atención, y en días útiles se procesarán a partir del momento en recibamos la suma a cambiar. Una vez recibida, el tiempo para que nosotros ejecutemos el cambio será alrededor de 30 minutos, siempre y cuando la operación haya sido realizada desde y hacia una cuenta del BCP, Interbank, o BBVA y no se hayan establecido tiempos diferentes.

Cambistape no influye en las operaciones que los bancos realizan, por ende, no se hace responsable por la tardanza en el envío de los fondos por parte del banco o bancos.

Dicho lo anterior, cabe señalar que la Empresa monitorea continuamente sus cuentas bancarias, y asume la obligación de completar tu operación, y notificar al respecto siempre y cuando sean recibidas antes de las 19:00 horas de un día útil. Si Cambistape recibiera los fondos pasada dicha hora, la operación será completada al siguiente día útil. Las solicitudes de operación recibidas durante un día no hábil también serán procesadas al siguiente día útil. Es importante destacar que los tiempos mencionados anteriormente se encuentran sujetos a variaciones que podrían ser ocasionadas por verificaciones de seguridad adicionales en función a la suma a cambiar y/o disponibilidad de las plataformas bancarias utilizadas. Estas condiciones son completamente ajenas a Cambistape y podrían retrasar el procesamiento de las operaciones.
<h1>¿Cómo transfiero mi dinero a las cuentas de Cambistape?</h1>
<strong> </strong>Cambistape recibirá los fondos en sus cuentas a través de transferencias bancarias y, por esta misma vía, transferirá dichos fondos a la cuenta señalada por el usuario. <strong>Es importante recalcar que la cuenta de destino de los fondos debe ser de titularidad del mismo usuario o perfil que crea la cuenta. Cambistape no realiza transferencias a cuentas de terceros.</strong><strong> </strong>
<h2>Asimismo, el usuario se compromete a realizar la transferencia de los fondos desde su cuenta bancaria, sin la intervención de cualquier tercero que pueda servir como intermediario.</h2>
<strong>¿Cambistape tiene más de una cuenta bancaria?</strong>

<strong> </strong>Sí. El usuario reconoce y asume la responsabilidad de verificar la cuenta de destino a la que le transfiere los fondos a Cambistape, para ello debe tener en consideración que tenemos cuentas en Soles y en Dólares, y que una difiere de la otra. Cambistape no se responsabiliza de ninguna manera por la negligencia del usuario en este aspecto.
<h2>¿Qué transferencias son consideradas interbancarias?</h2>
<strong> </strong>Cambistape tiene cuentas bancarias en Soles y Dólares en el Banco de Crédito del Perú (BCP), Interbank (Banco Internacional del Perú) y BBVA Continental. Las transferencias que se realicen desde y hacia otros bancos (Scotiabank, Banco Financiero, Banco de la Nación, entre otros) serán consideradas transferencias interbancarias diferidas. El tipo de cambio ofrecido en estas operaciones se mantendrá vigente por un plazo máximo de 24 horas hábiles desde la creación de la operación. Tanto el usuario como Cambistape tendrán que asumir el costo de la transferencia interbancaria. El costo de dichas transferencias depende del banco ejecutante.Es importante resaltar que el dinero de las transferencias interbancarias está sujeto a horarios, pudiendo ocasionar que el tiempo estimado para estas operaciones se dilate. El tiempo que tome la transferencia interbancaria diferida dependerá del banco emisor y de la Cámara de Compensación Electrónica (CCE). En ese sentido, el usuario asume la responsabilidad en caso de demora es los siguientes supuestos:
<ul>
    <li>Cuando la operación no sea realizada de lunes a viernes dentro del horario de atención, puesto que las operaciones sólo se procesan los días útiles (por ejemplo: si la transferencia se realiza en la tarde del viernes, ésta se procesará el lunes en la mañana o el siguiente día útil).</li>
    <li>Por errores en el ingreso de los datos registrados por el</li>
</ul>
<h2>¿Existen restricciones a las transferencias?</h2>
Sí. Cambistape se reserva el derecho, a (i) rechazar una transferencia de dinero solicitada sin justificación alguna; (ii) limitar el monto de las operaciones; (iii) solicitar información adicional para efectuar la transferencia de dinero; o (iv) tomar medidas razonables en relación a la transferencia de dinero a fin de cumplir con las leyes y normativa aplicables.
<h2>¿Cambistape realiza transferencias a cuentas de terceros?</h2>
<strong>Cambistape no realiza transferencias a cuentas de terceros. </strong>Es decir, todas las operaciones efectuadas a través de las plataformas de Cambistape sólo podrán ser ejecutadas entre cuentas del mismo usuario.
<h2>¿Cómo sé si ocurre un error en la transferencia?</h2>
En caso notes un error en el monto enviado o recibido como consecuencia del uso de nuestros servicios, comunícate inmediatamente con la empresa al correo electrónico <a href="mailto:contacto@kambista.com">contacto@Cambistape.com.</a>

De igual manera, si adviertes que has recibido montos distintos a los especificados en tu solicitud de operación, ponte en contacto con nosotros inmediatamente para poder realizar las gestiones necesarias para regularizar tu operación.

Ocurre un error cuando el usuario o Cambistape transfieren un monto distinto al acordado en la web o aplicativo (IOS o Android). Al ocurrir dicho error, se debe definir si el saldo excedente o deficiente es a favor del usuario o de Cambistape.

Tanto el usuario, como Cambistape se comprometen a transferir la cantidad necesaria para cumplir con lo acordado en la web o aplicativo (IOS o Android). En caso ocurra un error en la transferencia por parte del usuario que perjudique a la Empresa, el usuario se obliga a reintegrar el monto diferencial a las cuentas que mantiene Cambistape en un plazo no mayor a 15 días calendario contados a partir de la realización de la operación.

Si el usuario incumple con esta obligación, Cambistape se encontrará facultada a tomar las medidas que sean necesarias para recuperar el saldo a favor suyo. Mientras este saldo será considerado y tratado como una deuda a favor nuestro. Para cualquier comunicación o consulta sobre errores en las transferencias puedes comunicarte al correo <a href="mailto:contacto@Cambistape.com.">contacto@Cambistape.com.</a>
<h2>¿Puedo realizar transferencias a otras plazas?</h2>
<strong> </strong>A excepción de las cuentas bancarias que hayan sido abiertas en el BCP y aquellas de Interbank que hayan sido abiertas a través de nuestra plataforma, no es posible utilizar los servicios de Cambistape para realizar transferencias a cuentas bancarias que hayan sido abiertas fuera de Lima y Callao.
<h2>¿Cambistape podría rechazar una transferencia?</h2>
<strong> </strong>Cambistape puede rechazar una operación en caso no podamos validar el nombre del usuario con los datos asociados a la cuenta que recibirá la transferencia. En este caso, el usuario será responsable de asumir los costos que la transferencia de los fondos de vuelta pueda generar.
<h2>¿Puedo realizar mis depósitos en efectivo?</h2>
Cambistape <strong>no acepta depósitos en efectivo </strong>por mandato de la ley.

Si detectamos que el usuario ha realizado un depósito en efectivo, procederemos a devolver el dinero mediante transferencia a la cuenta bancaria vinculada al usuario en la plataforma. Esta transferencia podría demorar hasta un día hábil e implicar gastos que deberán ser asumidos por el usuario.
<h1>Tipo de cambio</h1>
<h2>¿Cuánto tiempo de vigencia tiene el tipo de cambio?</h2>
El tipo de cambio al momento de la transacción de la orden de compra, tendrá vigencia de 5 minutos desde el momento en que inicias tu operación. En este lapso de tiempo deberás transferir el monto a cambiar a la cuenta indicada por Cambistape para ser procesada.

Si la transacción no es completada dentro de las dos horas de creada la operación, Cambistape procederá a la anulación de la operación.

Para confirmar el tipo de cambio, el usuario deberá ingresar el código de su operación bancaria (en caso la transferencia se haya realizado desde la banca móvil del BCP, Interbank o BBVA continental), o adjuntar la confirmación de dicha transacción con la constancia de la transferencia en nuestra web (cuando la operación se haya realizado desde bancos distintos a los anteriormente señalados).
<h2>¿Cómo funciona el tipo de cambio preferencial?</h2>
Puedes acceder a un tipo de cambio preferencial si cambias montos mayores a S/30,000 o $10,000. Para ello, es necesario que indiques el monto y acceder a la parte de “Tipo de cambio preferencial” en la web o la app.

Una vez que aceptes tu tipo de cambio preferencial, debes crear tu operación en un periodo de hasta 5 minutos para poder conservarlo. Una vez creada tu operación, tienes hasta 10 minutos para realizar la transferencia de los fondos a las cuentas de Cambistape y adjuntar la constancia de tu transferencia en la web o el app.

Por temas de prevención de fraude, de manera aleatoria podríamos brindarte un tipo de cambio preferente de manera referencial que se consolidará al momento en que realices la transferencia de los fondos. Para poder fijar dicho tipo de cambio preferente, podríamos solicitarte tu comprobante de transferencia antes de ejecutar tu operación. De no tener en cuenta estos tiempos, podrías perder tu tipo de cambio preferencial debido al constante movimiento del dólar en el mercado.

El tipo de cambio preferencial aplica de lunes a viernes de 9:30 a 16:00 para cuentas del BCP, BBVA continental e Interbank, y para otros bancos de 9:30 a 12:30. El tipo de cambio preferencial no aplica a operaciones realizadas fuera del horario de atención.

Dependiendo del monto que vayas a cambiar, podría ser necesario que tengas que acercarte a la agencia de tu banco para poder realizar la transferencia a nuestras cuentas. Para estos casos te sugerimos tener en cuenta los tiempos señalados arriba para poder conservar tu tipo de cambio preferencial y evitar que se archive la operación y tengas que realizar una nueva cotización.
<h2>TÉRMINOS GENERALES</h2>
<strong>¿Cambistape cobra comisión por las operaciones de cambio?</strong><strong> </strong>

No cobramos ninguna comisión por el uso de nuestra plataforma. No obstante, podrían generarse cargos adicionales por cancelación de operaciones según lo señalado en este documento.
<h2>¿Qué cargos adicionales podrían generarse?</h2>
Los cargos adicionales son aquellos asociados a transferencias rechazadas o operaciones canceladas luego de la transferencia inicial de los fondos a las cuentas de Cambistape.
<h2>¿Cómo uso mi cupón de descuento?</h2>
Una vez que obtengas tu cupón de descuento debes ingresarlo en el espacio “Ingresa el cupón” debajo de la calculadora, en el aplicativo o web. Al presionar “Aplicar” podrás acceder a un tipo de cambio mejorado en tu operación.

Los cupones generalmente se entregan por ocasiones especiales o alianzas con socios comerciales estratégicos.
<h2>¿Cómo funcionan las alertas del tipo de cambio?</h2>
Puedes suscribirte a las alertas para conocer los movimientos del tipo de cambio. Las notificaciones pueden realizarse de manera diaria o cuando la ‘compra’ o ‘venta’ alcance el valor que indiques. La notificación se realizará por correo electrónico o por SMS al número de contacto brindado por el usuario con el tipo de cambio vigente al momento del envío de la alerta.

El registro de alertas es gratuito, y los usuarios pueden cancelar su suscripción en cualquier momento.
<h2>¿Cambistape emite boletas y facturas electrónicas?</h2>
Sí. Las boletas electrónicas serán emitidas a los usuarios que estén registrados como personas naturales y las facturas a los usuarios registrados como personas jurídicas.

La respectiva boleta y/o factura será emitida hacia el perfil que ejecute la operación, sea persona natural o persona jurídica.

Por normativa de la Superintendencia Nacional de Aduanas y de Administración Tributaria (SUNAT), las operaciones de cambio de divisas están exentas del pago de IGV, es decir, no existe un cobro adicional por concepto de IGV.
<h2>¿Cómo presento un reclamo?</h2>
Estamos comprometidos con brindarte los estándares más altos en nuestro servicio. Sin embargo, siempre podemos mejorar. Si tienes un reclamo sobre nuestros servicios puedes encontrar nuestro libro de reclamaciones virtual en <a href="http://www.kambista.com/">www.Cambistape.com</a> o escribirnos a <a href="mailto:contacto@kambista.com">contacto@Cambistape.com.</a>

&nbsp;
<h1>Prevención del Lavado de Activos y Financiamiento del Terrorismo (PLAFT)</h1>
Al hacer uso de los servicios de Cambistape declaras y garantizas que en todo momento realizaras tus operaciones observando todas las leyes anticorrupción y las normas relativas a la Prevención del Lavado de Activos y Financiamiento del Terrorismo (Leyes PLAFT), y que todos los recursos que utilices para ejecutar tus operaciones conforme a los presentes Términos y Condiciones provienen de fuentes lícitas

&nbsp;
<h2>Ley y Jurisdicción</h2>
La provisión del servicio y la resolución de cualquier reclamo, disputa o controversia que pueda surgir como consecuencia del mismo en los términos que se señalan en el Acuerdo se regirán por la ley peruana.
<h1>Cambios en los términos</h1>
Como se menciona en la introducción de este documento, Cambistape tiene la facultad de cambiar los términos y condiciones de uso unilateralmente y/o cuando la Ley lo requiera. Asimismo, de ser requerido por Ley, publicaremos un aviso sobre ello en nuestra web. Por otro lado, cuando no lo requiera la Ley o cuando sea necesario un cambio inmediato por cuestiones de seguridad, Cambistape podría no realizar dicho aviso.

Al continuar utilizando los servicios luego de dicho cambio, el usuario acepta las modificaciones realizadas a partir de la fecha de publicación o realización de dichos cambios.
<h1>Privacidad de datos</h1>
Cambistape se compromete a proteger tu privacidad, almacenando de forma encriptada tus contraseñas en los servidores de la empresa.

Toda la información sensible entre tu navegador y Cambistape se transfiere de forma encriptada utilizando SSL, por lo que debes asegurarte que tu navegador reconozca y valide el certificado de <a href="http://www.kambista.com/">www.Cambistape.com</a>.

Para mayor información puedes revisar nuestra Política de Privacidad de Datos.

                    </article>
                </div>
    )
}
export default RawTermCondition;