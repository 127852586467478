const RawPoliticadeprivacidad = () => {
return(
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-5">POLÍTICA DE PRIVACIDAD</h2>


<strong>Proteger su información, privacidad y datos financieros es muy importante para Cambistape S.A.C: (¨Cambistape”, “La Empresa” o “nosotros”)</strong>

<p>El presente documento contiene la Política de Privacidad de Cambistape y se encuentra en armonía con la normativa vigente sobre Protección de Datos Personales (Ley N° 29733 Y Decreto Supremo 003-2013- JUS). En el presente documento, se señalan las finalidades de la información que usted nos otorga, quiénes son los encargados de tratarla, las medidas de protección y los medios para ejercer sus derechos como titular de los Datos Personales, entre otros aspectos. Por favor lea con detenimiento este documento para entender la información que recolectamos sobre usted, cómo la usamos y las circunstancias bajo las cuales la compartiremos con terceras partes.</p>

<h5><strong>Consentimiento</strong></h5>

<p>Al hacer uso de Cambistape usted acepta esta Política de Privacidad y presta su consentimiento expreso para que la Cambistape recolecte y procese información personal del usuario. Asimismo, el usted declara estar de acuerdo con todos los aspectos expuestos en este documento y nos autoriza a tratar sus datos personales para las finalidades expuestas. Cabe precisar que, la Empresa no requiere consentimiento para tratar sus datos personales obtenidos de fuentes accesibles al público; asimismo, podrá tratar sus datos personales de fuentes no públicas, siempre que dichas fuentes cuenten con su consentimiento para tratar y transferir dichos datos personales.</p>

<h5><strong>Información Personal</strong></h5>

<p>Por información personal nos referimos a información que se encuentre asociada con una persona natural en específico y que puede ser razonablemente utilizada para identificar a esta persona.</p>
<p>La información personal no incluye a la información que haya pasado por un proceso de anonimización y que por ende no puede ser utilizada para identificar a una persona.</p>

<strong>Información que podríamos recolectar sobre usted:</strong>
<ul>
    <li><strong>Podríamos recolectar y procesar los siguientes datos sobre usted:</strong>
        <ol style={{"listStyle": "lower-latin"}}>
            <li>Información proporcionada por usted al llenar los formatos en nuestra página web, y aplicativo, y/o al interactuar con nosotros por vía telefónica, correo electrónico o de otra forma. Esto incluye, pero no se limita a información proporcionada al registrarse como usuario para utilizar la página web, realizar operaciones utilizando la página web o reportar algún problema en la página web.</li>
            <li>La información podría incluir su nombre, fecha de nacimiento, dirección, números de cuenta bancarios e interbancarios, descripción personal, ubicación geográfica, fotografía, copias de documentos de identidad, y entre otros.</li>
        </ol>
    </li>
    <li><strong>Información que recolectamos sobre usted: Con respecto a cada visita que realiza a nuestra página web, podríamos recolectar automáticamente la siguiente información:</strong>
        <ol style={{"listStyle": "lower-latin"}}>
            <li>Detalles de las transferencias de dinero realizadas a través de la página web, incluyendo la ubicación geográfica desde la cual se genera.</li>
            <li>Ubicación técnica, incluyendo la dirección IP utilizada para conectarse desde su computadora a internet, la información de ingreso a su cuenta de usuario, tipo de navegador y versión, configuración horaria, plug-ins asociados, sistema operativo y plataforma, etc.</li>
            <li>Información sobre su visita, incluyendo el clickstream completo de URL hacia, a lo largo de, y desde nuestra página web (incluyendo fecha y hora); lo que se vi ó y las búsquedas que se realizaron; tiempos de respuesta de la página, errores de descarga, tiempos de visita a ciertas páginas, información de interacción con la página y métodos usados para salir de la página, así como cualquier número de teléfono utilizado para contactar a servicio al cliente.</li>
            <li>Información que recibimos de otras fuentes: Nosotros podríamos recibir información acerca de usted si utiliza cualquier otra página web operada por nosotros o asociada a cualquier otro servicio provisto por nosotros. Nosotros también podríamos recibir información de los bancos u otras instituciones financieras utilizadas para transferir fondos. También trabajamos cercanamente con terceras partes (como agencias de historial crediticio, proveedores de análisis de datos, redes de publicidad, negocios aliados, entre otros) y podríamos recibir información de ellos.</li>
        </ol>
    </li>

    <li><strong>Usos de la información recolectada</strong>
        <ol style={{"listStyle": "lower-latin"}}>
            <li>La recolección y tratamiento de los Datos Personales, así como los eventuales encargos, transferencias y flujos transfronterizos que se realicen sobre estos a empresas vinculadas o proveedores de Cambistape, tiene las siguientes finalidades:
                <ol>
                    <li><strong>Información proporcionada por usted:</strong>
                        <ul>
                            <li>Para cumplir con nuestras obligaciones adquiridas en el marco de cualquier contrato entre usted y nosotros (incluyendo, pero sin limitarse a los Términos y Condiciones) y para proveerle de información, productos y servicios que requiera.</li>
                            <li>Para proveerle información sobre otros bienes y servicios que ofrecemos, que sean similares a aquellos que usted ya utiliza.</li>
                            <li>Para el envío de anuncios y oferta de servicios por medio del correo electrónico registrado en la página web, envío de SMS y/o llamadas al número telefónico que el Cliente ha registrado.</li>
                            <li>Para la ejecución y mantenimiento de otras relaciones contractuales mantenidas entre Usted y Cambistape, tales como gestiones de cobranza y del desarrollo de sus operaciones,</li>
                            <li>Para asegurarnos que el contenido presentado en nuestra página web sea el más idóneo de acuerdo a sus preferencias y dispositivos.</li>
                            <li>Para cumplir con todos los requerimientos legales y regulatorios impuestos por las autoridades competentes.</li>
                        </ul>
                    </li>

                    <li><strong>Información que recolectamos sobre usted: </strong>
                        <ul>
                            <li>Para administrar nuestra página web y para operaciones internas como solución de problemas operativos, análisis de datos, pruebas, así como fines estadísticos y de investigación. </li>
                            <li>Para permitirle participar en partes interactivas de nuestros servicios cuando usted desee hacerlo. </li>
                            <li>Para asegurar, mantener y mejorar la seguridad de nuestra página web.</li>
                            <li>Para medir y entender la efectividad de la publicidad enviada a usted y a otros, con el fin de poder enviarle publicidad relevante y adecuada a sus intereses. Para brindarle recomendaciones acerca de otros bienes y servicios que podrían interesarle.</li>
                        </ul>
                    </li>


                    <li><strong>Información que recibimos de otras fuentes: </strong>
                        <p>Nosotros podríamos combinar la información brindada por usted con información recolectada de otras fuentes con la finalidad de actualizar datos que se encuentren incompletos o sustituirlos, en base a consultas de fuentes de acceso público.</p>
                        <p>Esta información, y la información combinada, podría ser usada para cualquiera de los fines mencionados anteriormente en los literales a) y b)</p>
                        <ol style={{"listStyle": "lower-latin"}}>
                            <li><strong>Revelación de su información:</strong>
                                <p>Nosotros podríamos compartir su información personal con cualquier otro miembro de nuestro grupo, es decir subsidiarias, holding o sus subsidiarias.</p>
                            </li>

                            <li><strong>Podríamos compartir su información con terceros seleccionados, incluyendo:</strong>
                                <ul>
                                    <li>Negocios aliados, proveedores o terceros contratados para proveer cualquier contrato que tengamos con usted o para ayudar a mejorar nuestro servicio.</li>
                                    <li>Agencias de publicidad que requieran datos para seleccionar y enviar avisos relevantes a usted o terceros.</li>
                                    <li>Proveedores de análisis de datos y de buscadores que nos asistan en la mejora y optimización de nuestros servicios.</li>
                                    <li>Entes reguladores o autoridades en el ejercicio de sus funciones que así nos lo demanden.</li>
                                </ul>
                            </li>

                            <li><strong>Podríamos compartir su información personal con terceros:</strong>
                                <p>En caso que se venda o compra cualquier unidad del negocio o activo y sea necesario revelar su información personal a potenciales compradores o vendedores de dichas unidades de negocio o activos. </p>
                                <p>Si Cambistape o todos sus activos son adquiridos por un tercero, en cuyo caso la información personal recolectada podría ser uno de los activos a transferir.</p>
                                <p>Si es que estamos bajo obligación de revelar o compartir su información personal para cumplir con cualquier obligación legal, o para poder aplicar los Términos y Condiciones o cualquier documento al cual se haga referencia en éstos, o para proteger los derechos, propiedad y seguridad de Cambistape, de nuestros usuarios o de terceros. Esto incluye intercambiar información con otras compañías y organizaciones con objetivos de protección frente a fraudes, investigación relacionada crímenes financieros o proveer datos para asistir al cumplimiento de la ley.</p>
                            </li>

                            <li><strong>Dónde almacenamos su información personal:</strong>
                                <p>La información recolectada podría ser transferida y almacenada en un destino fuera del Perú. Esta podría ser también procesada por trabajadores operando fuera del Perú que trabajen para nosotros o para algún proveedor. Estos trabajadores podrían estar asociados a actividades como la provisión de servicios de soporte o de análisis de datos. Al enviar su información personal usted reconoce que se encuentra de acuerdo con esta transferencia, almacenamiento o procesamiento. Nosotros tomaremos todas las medidas necesarias para asegurarnos de que su información sea tratada de forma segura y de acuerdo con las Políticas de Privacidad que se establecen en este documento.</p>
                                <p>Toda la información que nos envíe se almacena en nuestros servidores seguros. Cualquier transacción de pago se encriptará usando SSL y/o alguna otra tecnología de seguridad cibernética. Usted es responsable de mantener la confidencialidad de la contraseña seleccionada para acceder a ciertas partes de la página web. Le pedimos no compartir su contraseña con nadie ya que Cambistape no se hará responsable ante la pérdida, mal uso o robo de su contraseña.</p>
                                <p>Desafortunadamente la transmisión de información a través de internet no es completamente segura. A pesar de que haremos el mayor esfuerzo posible para proteger su información, no podemos garantizar la seguridad de la información transmitida a nuestra página web; cualquier transmisión es bajo su propio riesgo. Una vez recibida la información, utilizamos procedimientos estrictos y procesos de seguridad para evitar cualquier acceso a ésta sin autorización.</p>
                                <p>Nosotros restringimos el acceso a su información personal a empleados de Cambistape que tengan necesidad de conocerla por razones asociadas a los servicios brindados. Continuamente capacitamos a nuestros trabajadores sobre la importancia de la confidencialidad y privacidad de la información de los usuarios. Mantenemos procesos que cumplen con los requerimientos legales para proteger su información personal de cualquier acceso no autorizado.</p>
                            </li>

                            <li><strong>Cookies:</strong>
                                <p>Nuestra página web utiliza cookies para distinguirlo de cualquier otro usuario. Esto nos ayuda a brindarle una buena experiencia cuando navegues por nuestra página web y, al mismo tiempo, nos permite mejorarla. Los Cookies son pequeños ficheros de información que se almacenan en tu ordenador o dispositivo móvil. Esto es una práctica común en todos los sitios web. Las cookies no se usan para identificarte personalmente. </p>
                                <p>La utilización de nuestro sitio web implica su aceptación del uso de cookies por nuestra parte.</p>
                                <p>Recuerda que es posible desactivar las cookies almacenadas en su computadora cambiando la configuración de su navegador. Sin embargo, esto podría afectar el correcto funcionamiento de nuestra página web.</p>
                            </li>

                            <li><strong>Sus derechos:</strong>
                                <p>Para poder ejercer estos derechos los usuarios deberán dirigir la solicitud a la dirección de correo electrónico: contacto@cambistape.com con el asunto “Protección de Datos Personales”, consignando sus datos, acreditando su identidad y los motivos de su solicitud. </p>
                                <p>Nuestra página web podría contener links a otras páginas web de empresas aliadas, afiliadas o publicitarias. En ese sentido, debe tener en cuenta que si usted sigue un link hacia cualquiera de estas páginas las mismas disponen de sus propias políticas de privacidad, de las cuales usted deberá estar al tanto. Cambistape no asumirá ni aceptará ninguna responsabilidad dichas políticas.</p>
                            </li>

                            <li><strong>Acceso a la información:</strong>
                                <p>Dependiendo de las leyes aplicables, usted podría tener el derecho a acceder a la información que tenemos sobre usted. Su derecho al acceso podrá ser ejecutado de acuerdo a la legislación de protección de información relevante. Cualquier solicitud de acceso podría estar sujeta al pago de una tarifa adicional para cubrir los costos asociados a brindarle dicha información.</p>
                            </li>

                            <li><strong>Cambios en la Política de privacidad :</strong>
                                <p>Cualquier cambio sustancial que se realice a nuestra Política de privacidad en el futuro será publicado en esta página y notificada a usted vía correo electrónico, de ser necesario. Se recomienda revisar frecuentemente esta página para ver cualquier actualización o cambios a nuestra Política de privacidad.</p>
                            </li>

                            <li><strong>Ley y Jurisdicción:</strong>
                                <p>Esta Política de Privacidad, y cualquier disputa o reclamo que surjan como consecuencia de la misma se encuentra normada por la ley peruana. </p>
                            </li>


                            <li><strong>Resolución de conflictos :</strong>
                                <p>Las Partes se someten a la competencia de los jueces y tribunales de Lima, Cercado para resolver cualquier duda, diferencia o controversia que pudiera surgir entre las Partes respecto a los alcances de las Políticas de Privacidad de Cambistape, renunciando al fuero que pudiera corresponderles en razón a su domicilio.</p>
                            </li>


                            <li><strong>Contacto</strong>
                                <p>Preguntas, solicitudes y comentarios asociados a esta Política de privacidad son bienvenidos y deberán ser dirigidos a contacto@cambistape.com</p>
                            </li>

                        </ol>
                    </li>

                </ol>
            </li>
        </ol>
    </li>

</ul>











                    </article>
                </div>
    )
}
export default RawPoliticadeprivacidad;