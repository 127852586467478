import { useState } from 'react'
import InputEmail from './InputEmail'

const FormRecoverPassword = () =>{

	const [value, setValue ] = useState(
	{
		email:"",
		check1:false,
		check2:false,
		check3:false
	}
		)

	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const submit = async(event) =>{
		event.preventDefault();
		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });
		  			
			if ( 
				//["",undefined,"undefined"].includes(window.getCookie("commerce_lead_token")) 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {

			    const formData = new FormData();
			    formData.append('email', value.email);	    

				const response = await fetch(window.path_service+"/admin/forgot_password",{method:'POST',body:  formData })
				const data = await response.json()

				if ( data.hasOwnProperty("status") && data.status ) {
			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          });         					
					modaLoading.close();
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 3500
				    }); 
				    modaLoading.close();					
				}
			}					
	}	

	return (
				<form className="js-form-forgot-password" onSubmit={submit}>
					<div className="form-group-flex">
					<br/>
					<InputEmail 
					    validated="yes"
					    name="email"
					    placeholder="Nombre de Usuario"
					    value = {value.email}
					    classWrap="form-group form-group-full"
					    clasInput="cb-form-input"
					    onChange = {handleChangeForm}
					    />

			            
			            <div className="form-group form-group-full js-form-register">
				            <div className="form-check">
				                <input className="form-check-input" type="checkbox" id="terminos-1" 
				                onChange={handleChangeForm} name='check1' checked={value.check1}
				                 />
				                <label htmlFor="terminos-1" className="form-check-label check-label">
				                    <a style={{"color": "#ff6700"}} target="_blank" href="/terminos-y-condiciones">Acepto Términos y Condiciones</a>
				                </label>
				            </div>
			            
				            <div className="form-check mt-3">
				                <input className="form-check-input" type="checkbox" id="politicas-1" 
				                onChange={handleChangeForm} name='check2' checked={value.check2}
				                />
				                <label htmlFor="politicas-1" className="form-check-label check-label">
				                    <a style={{"color": "#ff6700"}} target="_blank" href="/politica-de-privacidad">Acepto Política de Privacidad</a>
				                    <span style={{"color": "#ff6700"}}>y</span>
				                    <a style={{"color": "#ff6700"}} target="_blank" href="/politicas-de-cookies">Política de Cookies</a>
				                </label>
				            </div>

				            <div className="form-check mt-3 mb-4">
				                <input className="form-check-input" type="checkbox" id="tratamiento-1"
				                onChange={handleChangeForm} name='check3' checked={value.check3}
				                />
				                <label htmlFor="tratamiento-1" className="form-check-label check-label">
				                    <a style={{"color": "#ff6700"}} target="_blank" href="/politica-de-privacidad#consentimiento">
				                        Acepto tratamiento de datos personales para fines adicionales a los esenciales del servicio
				                    </a>
				                </label>
				            </div>
			            </div>

					</div>

					<div className="form-group-flex">

		                   {
						    (
							    (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value.email) ) &&
							    value.check1 &&
							    value.check2 &&
							    value.check3
							)
		                   	?

		                   <div className="fm-item-form form-group form-group-full">
		                     <button className="fm-btn-opera">RECUPERAR</button>
		                   </div>

		                   :
		                   <div className="fm-item-form disabled form-group form-group-full">
		                     <button disabled className="fm-btn-opera">RECUPERAR</button>
		                   </div>		                   
		                   }
					</div>



				</form>		
	)
}

export default FormRecoverPassword;