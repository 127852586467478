import { useState,useEffect } from 'react'
import CuentaBancaria from '../Form/CuentaBancaria'
import MenuMyAcount from '../MenuMyAcount'

const PageMisCuentas = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

	const [value, setValue ] = useState( 
		{
			id:null,
			cuenta_bancaria : [],
		} 
	)


	const handleCuentaBancaria = (list) => {
		setValue((state) => (
		{
	      ...state,
	      cuenta_bancaria: list
	    }
	    ))	 		
	}		

	return(
		<div>
				<main  className="wrap-content page-interna">
					<div className="shop-container-register">
						<div id="ms-profile">
						  <div className="ms-section">
						    <div className="ms-tab-profile ms-payment" style={{zIndex: "auto"}}>
						      <div className="ms-lateral-a">
						        <div className="ms-header-menu-profile">
						          <button className="ms-active-menu">Mis Datos Personales</button>
						        </div>
						        <div className="ms-body-menu-profile">
						          <div className="ms-list-menu-profile">
						            <div className="ms-header-list-menu">Mi cuenta</div>
						            	<MenuMyAcount active={"mis-cuentas"} />
						          </div>
						        </div>
						      </div>
						      <div className="ms-lateral-b">
						        <div className="ms-body-menu-profile">
						          <div className="ms-info-menu-selected">
						            <div className="ms-title">Mis Cuentas Bancarias</div>
						            	<CuentaBancaria litCuenta={value.cuenta_bancaria} handleCuentaBancaria={handleCuentaBancaria} />
						            <div className="ms-separate"></div>

						          </div>
						        </div>
						      </div>
						    </div>
						  </div>
						</div>
					</div>
				</main>

	    </div>	
	)
}
export default PageMisCuentas;