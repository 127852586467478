const Textarea = ({label,validated,classWrap="ms-item",clasInput="fm-form-input fm-form-area",...rest}) =>{
	
	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
			
			{
				validated==="yes" ?
					(rest.value.length === 0 )?
					<textarea className={clasInput+" disabled"} {...rest} id={rest.name}></textarea>
					:
					<textarea className={clasInput} {...rest} id={rest.name}></textarea>
				:
				<textarea className={clasInput} {...rest} id={rest.name}></textarea>
			}
			
		</div>
	)
}
export default Textarea;