import { useEffect } from 'react'
import Pedido from '../Form/Pedido'

const PagePedidoNew = ({user,myLazyLoad,myListCart}) =>{
	console.log(user)
	return (
		<div id="ms-profile">
			<Pedido type_page="new" vuser = {user} />
	    </div>
	)

}

export default PagePedidoNew;