import {useState, useEffect} from 'react'

import PageMisPedidos from './components/PageMisPedidos'
import User from './components/User'

import Pageterminosycondiciones from './components/Pageterminosycondiciones'
import Pagepoliticadeprivacidad from "./components/Pagepoliticadeprivacidad";
import Pagepoliticasdecookies from "./components/Pagepoliticasdecookies";
import Pagequienessomos from "./components/Pagequienessomos";
import Pagepreguntasfrecuentes from "./components/Pagepreguntasfrecuentes";
import PageDetailblog from "./components/PageDetailblog";


import PageMiperfil from "./components/PageMiperfil"
import PageDireccion from "./components/PageDireccion"
import PageCambiarClave from "./components/PageCambiarClave"
import PageMisCuentas from "./components/Page/PageMisCuentas";
import PageLogin from "./components/PageLogin";
import PageRegistro from "./components/PageRegistro";
import PageRecoverPassword from "./components/PageRecoverPassword";
import PageBlog from "./components/PageBlog";
import PageContacto from "./components/PageContacto";
import PageLibroReclamos from "./components/PageLibroReclamos";
import Home from "./components/Page/Home";
import PagePedidoNew from "./components/Page/PagePedidoNew";
import PagePedidoView from "./components/Page/PagePedidoView";
import Header from './components/Header'
import Footer from './components/Footer'


    var myLazyLoad = new window.LazyLoad({
        elements_selector: ".shop-lazy-image",
        callback_load: function(element){
          //window.$(element).parent('span').addClass('loaded');
          window.$(element).removeAttr('data-original','');
          window.$(element).removeClass('shop-lazy-image');
          

        },
        callback_error: function(element){
          window.$(element).parent('span').addClass('loaded');
          window.$(element).attr('src',window.path_service+'/images/no-image.png').removeClass('error').addClass('loaded');
          window.$(element).attr('data-origin',window.path_service+'/images/no-image.png');
        }
    });

    window.myLazyLoad = myLazyLoad;

    window.listMoneda = [
        {id :1 , name:"Soles", simbolo :"S/." },
        {id :2 , name:"Dolar", simbolo : "$"},
        {id :3 , name:"Euro",simbolo : "€" }
    ]

window.currencyFormat = (data,moneda = 2) =>{
    data = parseFloat(data)
    const datMoneda = window.listMoneda.filter(function(params) {
        return params.id === parseInt(moneda)
    })
    return (datMoneda.length === 1 ? datMoneda[0].simbolo : window.listMoneda[1].simbolo) + data.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const AppPages = ({form}) => {
    const [miproductos, setMiproductos] = useState([])
    const [validateproductos, setValidateproductos] = useState([])

    const [user, setUser] = useState([])
    const [stats, setStats] = useState({customers: 0,products:0})

    const [subTotal, setSubTotal] = useState("0.00")
    const [itemshopping, setItemshopping] = useState([])
    const [resumen, setResumen] = useState([])
    
    const [menus, setMenus] = useState([])
    const [currenmoneda, setCurrenmoneda ] = useState({
        envio:1000,
        recibo:0,
        rate : {label:"compra",value: 0 }
    })  

    const getinfoPay = (item) => {
        console.log("item de pago", item )
        currenmoneda.rate =item.rate
        currenmoneda.envio =item.envio
        currenmoneda.recibo =item.recibo
        setSubTotal(item.sub_total.toFixed(2))

        let totalPay = item.total;
        let subtotal = item.sub_total;            
        let sub_total_pay = item.sub_total;
        let shipping_price = 0;
        let mount_discount = 0;
        let nombre_cupon = "";
        let free_shipping = false;
        let is_next_step = true;

        if (free_shipping) {
            shipping_price = 0;
        }

        totalPay = sub_total_pay + shipping_price - mount_discount;

        if (totalPay<35) {
            is_next_step = false;
        }

        var contentData_resument = {
            tiene_cupon: "",
            infocupon: [],
            activedescuentoCupon: "0",
            mountdescuento: mount_discount.toFixed(2),
            textocupon: "",
            free_shipping: free_shipping,
            shipping_price: shipping_price.toFixed(2),
            nombrecupon: nombre_cupon,          
            total_descuentos_productos: item.total_descuento,
            totalPrice: totalPay.toFixed(2),            
            subtotal:subtotal.toFixed(2),
            nextstep:is_next_step
        };

        setCurrenmoneda(item)
    }
       
    const handleMiproductos = (data) =>{
        setMiproductos(data)
    }

    const handleSubTotal = (data) =>{
        setSubTotal(data)
    }

    const myinfoDetail = async () =>{
        var myHeaders = new Headers();
        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
        }
            
        const response = await fetch(window.path_service+"/user/detail", { method:'POST' , headers: myHeaders})
        const data = await response.json()

        if (data.status && data.hasOwnProperty("user") ) {
            console.log(data.user)
            setUser(data.user)

            if ( data.user.hasOwnProperty("email") && data.user.email !== "" ) {
                
                localStorage.emailUser = data.user.email;

                if (window.pagenow !== "mi-perfil" && (data.user.has_dni === false || data.user.has_phone === false) ) {
                    window.location.href = "/mi-perfil";
                }
                
            }else{
             window.location.href = "/login";   
            }
        }else{
            localStorage.removeItem("token_authlead");
        }

    }

    console.log(user)


    const myListCart = async () =>{

    }

    const loadMenu = (value) => {
        setMenus(value)
    }

    useEffect(() => {

        if ( ["login","registro","forgot-pass"].includes(window.pagenow) ) {
              window.$("body").removeClass('nav-md');
              window.$("body").addClass('login');
              localStorage.clear()
        }else{
            myinfoDetail();
        }

    }, []);

    return (
        <div>
            <Header user = {user} />

            <div className="wrap-body">
                <main className="wrap-content page-interna">
                    <div className="page-content">

                        {
                            form === "home" || form === "" ?
                                <Home
                                    user = {user}
                                    currenmoneda = {currenmoneda}
                                    getinfoPay = {getinfoPay}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                            : null
                        }

                        {
                            form === "mis-pedidos" ?
                                <PageMisPedidos
                                    user = {user}
                                    
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />     
                                :
                                null
                        }

                        {
                            form === "terminos-y-condiciones" || form === "terminos-condiciones" ?
                                <Pageterminosycondiciones
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "politica-de-privacidad" ?
                                <Pagepoliticadeprivacidad
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "contacto" ?
                                <PageContacto
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        
                        {
                            form === "libro-reclamos" ?
                                <PageLibroReclamos
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }
                        
                        {
                            form === "mis-direcciones" ?
                                <PageDireccion
                                    user = {user}
                                    
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }                 

                        {
                            form === "cambiar-clave" ?
                                <PageCambiarClave
                                    user = {user}
                                    
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        {
                            form === "mis-cuentas" ?
                                <PageMisCuentas
                                    user = {user}
                                    
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        {
                            form === "mi-perfil" ?
                                <PageMiperfil
                                    user = {user}
                                    
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }                 

                        
                        {
                            form === "politicas-de-cookies" ?
                                <Pagepoliticasdecookies
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "quienes-somos" ?
                                <Pagequienessomos
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "preguntas-frecuentes" || form === "page/preguntas-frecuentes" ?
                                <Pagepreguntasfrecuentes
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }


                        {
                            form === "login" || form === "iniciar-sesion" ?
                                <PageLogin
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        
                        {
                            form === "registro" ?
                                <PageRegistro
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }
                        


                        {
                            form === "recuperar-contraseña" || form === "forgot-pass" ?
                                <PageRecoverPassword
                                    user = {user}
                                    
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    
                                    
                                    
                                    
                                    loadMenu={loadMenu}
                                    
                                    menus={menus}
                                    
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }


                        {
                            form === "orders/new" ?
                                <PagePedidoNew
                                    user = {user}
                                    subTotal = {subTotal}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }                    



                        {
                            !form.includes("orders/new") && form.includes("orders/")  ?
                                <PagePedidoNew
                                    user = {user}
                                    myListCart = {myListCart}
                                    myLazyLoad = {myLazyLoad}
                                />

                                :
                                null
                        }   



                    </div>
                </main>
            </div>

            <Footer />

        </div>
    )
}

export default AppPages;