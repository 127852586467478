import { useState } from 'react'
import InputPassword from './InputPassword'

const FormChangePassword = () =>{
	const [value, setValue ] = useState(
	{
		confirmPassword:"",
		newPassword:"",
		oldPassword:""
	}
		)

	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const submit = async(event) =>{
		event.preventDefault();

		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });
		  			


			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {

	            var myHeaders = new Headers();
	            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
	            myHeaders.append("Content-Type", "application/json");        
			    
				const response = await fetch(window.path_service+"/user/change_password",{method:'POST',body:  JSON.stringify(value), headers: myHeaders })
				const data = await response.json()

				if ( data.hasOwnProperty("status") && data.status ) {

			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          });         					

					modaLoading.close();
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 2500
				    }); 
				    modaLoading.close();					
				}
				

			}			
		

	}

	return(
		<form className="ms-form-payment-register js-form-change-password" onSubmit={submit}>

							<InputPassword 
								label = "Contraseña actual"
							    validated="yes"
							    name="oldPassword"
							    placeholder="Password"
							    value = {value.oldPassword}
							    classWrap="ms-item"
							    clasInput="cb-form-input ms-input"
							    onChange = {handleChangeForm}
							    />

							<InputPassword 
								label = "Contraseña nueva"
							    validated="yes"
							    name="confirmPassword"
							    placeholder="Password"
							    value = {value.confirmPassword}
							    classWrap="ms-item"
							    clasInput="cb-form-input ms-input"
							    onChange = {handleChangeForm}
							    />

							<InputPassword 
								label = "Confirma tu nueva contraseña"
							    validated="yes"
							    name="newPassword"
							    placeholder="Password"
							    value = {value.newPassword}
							    classWrap="ms-item"
							    clasInput="cb-form-input ms-input"
							    onChange = {handleChangeForm}
							    />


		                   {
						    (
						    	value.confirmPassword !== "" && 
							    value.newPassword !== "" && 
							    value.oldPassword !== ""
							)
		                   	?
				                   <div className="fm-item-form">
				                     <button className="fm-btn-opera">Guardar Cambios</button>
				                   </div>		                   		
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Cambios</button>
		                   </div>		                   
		                   }
		</form>
	)	
}
export default FormChangePassword;