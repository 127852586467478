module.exports = global.config = {
	account: {
		name: "Cambistape SAC",
		nickname : "cambistape",
		phone : "947-983-553",
		cphone: "51947983553",
		website: "https://www.cambistape.com"
	},
	months:[
		{id:"01",name:"Enero"},
		{id:"02",name:"Febrero"},
		{id:"03",name:"Marzo"},
		{id:"04",name:"Abril"},
		{id:"05",name:"Mayo"},
		{id:"06",name:"Junio"},
		{id:"07",name:"Julio"},
		{id:"08",name:"Agosto"},
		{id:"09",name:"Setiembre"},
		{id:"10",name:"Octubre"},
		{id:"11",name:"Noviembre"},
		{id:"12",name:"Diciembre"}
	]	
};
