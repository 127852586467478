import { useState, useEffect, useRef } from 'react';

const useStateCurrency = ({montorates,getinfoPay}) =>{
	const [itemxhr, setItemxhr] = useState({
			envio:1000,
			recibo:0,
			rate : {label:"compra",value: 0 },
			sub_total : 0,
			total_descuento : 0,
			cupon: [],
			token : "",
			total_sin_descuentos : 0
		})
	
	const abortControllerRef = useRef(null);

	useEffect(() => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

	    ;(async () => {
	    	
	    	const items_inits = await fetch_properties(  (![undefined,"undefined","",null].includes(montorates)) ? montorates : itemxhr )     
	      
	      if (items_inits.status) {
	      	setItemxhr({
				envio:items_inits.total_sin_descuentos,
				token: items_inits.token,
				recibo:items_inits.recibo,
				rate : {label:items_inits.data.sku,value: items_inits.data.price },
				sub_total : items_inits.sub_total_pay,
				total_descuento : items_inits.total_con_descuentos,
				total_sin_descuentos : items_inits.total_sin_descuentos
			})

			getinfoPay({
				envio:items_inits.total_sin_descuentos,
				recibo:items_inits.recibo,
				token: items_inits.token,
				rate : {label:items_inits.data.sku,value: items_inits.data.price },
				cupon: items_inits.info_cupon,
				sub_total : items_inits.sub_total_pay,
				total_descuento : items_inits.total_con_descuentos,
				total_sin_descuentos : items_inits.total_sin_descuentos
			})

	      }
	      
	    })()

  }, [])

  const more = async (data) => {
  		const more_items = await fetch_properties(data)

    	if (more_items.status ) {

	      	setItemxhr({
				envio:more_items.total_sin_descuentos,
				recibo:more_items.recibo,
				token: more_items.token,
				rate : {label:more_items.data.sku,value: more_items.data.price },
				sub_total : more_items.sub_total_pay,
				total_descuento : more_items.total_con_descuentos,
				total_sin_descuentos : more_items.total_sin_descuentos				
			})

			getinfoPay({
				envio:more_items.total_sin_descuentos,
				recibo:more_items.recibo,
				token: more_items.token,
				rate : {label:more_items.data.sku,value: more_items.data.price },
				cupon: more_items.info_cupon,
				sub_total : more_items.sub_total_pay,
				total_descuento : more_items.total_con_descuentos,
				total_sin_descuentos : more_items.total_sin_descuentos				
			})
    	}
  	
  }

	const fetch_properties = async (currenmoneda) =>{
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const newAbortController = new AbortController();
		abortControllerRef.current = newAbortController;


		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
		myHeaders.append("Content-Type", "application/json");            

	    const response = await fetch( window.path_service+"/api/list/cart",
	    {
	      signal: newAbortController.signal,
	      method:'POST',
	      body: JSON.stringify(currenmoneda), 
	      headers: myHeaders
	    
	    })

    const data = await response.json()
	return data;
		
	}

  return [itemxhr, more]

}
export default useStateCurrency;