import CambioMoneda from '../Form/CambioMoneda'

const Home = ({user,removeProduct,subTotal,miproductos,myLazyLoad,myListCart,handleMiproductos,handleSubTotal,currenmoneda,getinfoPay}) =>{
    return (
        <section className="main">
          <div className="banner">
            <div className="banner-item">
              <div className="ads-img">
                  <img className="featurette-image img-responsive center-block yape-image-desktop" id="imgCelular" src="//Cambistape.com/images/banner.png" alt="Cambistape sac" />
              </div>
            </div>
            <div className="banner-item rigth">
                <CambioMoneda fechadespacho={new Date().toLocaleDateString("fr-CA")} pricedespacho={0} currenmoneda={currenmoneda} getinfoPay={getinfoPay} />
            </div>    
          </div>
          <div className="worked-with-bank">
            <div className="worked-item">
              <h1 className="title">Operaciones inmediatas<span> (5 - 25min)</span></h1>

              <ul>
                <li>
                  <img src="//Cambistape.com/images/bcp.svg" alt="bcp"/>
                </li>
              </ul>
            </div>

            <div className="worked-item">
              <h1 className="title">Operaciones Interbancarias<span> (hasta 1 día útil)</span></h1>

              <ul>
                <li>
                  <img src="//Cambistape.com/images/bbva.svg" alt="bbva" />
                </li>       
                <li>
                  <img src="//Cambistape.com/images/interbank.svg" alt="interbank" />
                </li> 
                <li>
                  <img src="//Cambistape.com/images/scotiabank.svg" alt="scotiabank" />
                </li>               
                <li>
                  <i className="fa fa-university" aria-hidden="true"></i>
                </li>                       
              </ul>
            </div>

            <p className="txt-message">Transferencias interbancarias sujetas a cobro de comisión asumido por el cliente</p>
          </div>


          <div className="schedule-worked">

          </div>


          <div className="steps-pay">
            <div className="sec-title">
              <h2>¿Cómo funciona?</h2>
              <p>Tendrás tu cambio listo para realizar tus operaciones.</p>     
            </div>
            <div className="steps-list">
                    <div>
                      <div>
                        <img src="https://s3.us-east-2.amazonaws.com/samishop.com/pe/images/icons/icon-ingresa-yape.webp" alt="" />
                      </div>
                      <div className="steps-text">
                        <p>1. Ingresa a Web</p>
                        <p>Ingresa a Cambistape,<span>Cotiza tu operación</span> y click en el botón “Inicia tu operación” .</p>
                      </div>
                    </div>
                    
                    <div>
                      <div>
                        <img src="https://s3.us-east-2.amazonaws.com/samishop.com/pe/images/icons/icon-compra-plan.webp" alt="" />
                      </div>
                      <div className="steps-text">
                        <p>2. Registrate</p>
                        <p>Una vez cotizado tu operación <span>Registrate</span>. haciendo click en el botón superior “Registrate”.</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <img src="https://s3.us-east-2.amazonaws.com/samishop.com/pe/images/icons/icon-abre-tienda.webp" alt="" />
                      </div>
                      <div className="steps-text">
                        <p>3. Transfiere</p>
                        <p>Ingresa a tu cuenta y dirigete a operaciones y
                          <span>“transfiere el monto de la operación”</span>.</p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <img src="https://s3.us-east-2.amazonaws.com/samishop.com/pe/images/icons/icon-ingresa-cupon.webp" alt="" />
                      </div>
                      <div className="steps-text">
                        <p>4. Recibe el dinero</p>
                        <p>Verificaremos el monto de la transferencia e inmediato <span>Depositamos</span> a tu cuenta añadida.</p>
                      </div>
                    </div>

            </div>
            
          </div>

          <div className="why-use">
            <div className="sec-title">
              <h2>¿Por que Usar Cambistape?</h2>
              <p>Tendrás tu cambio listo para realizar tus operaciones.</p>     
            </div>
            <div className="use-easy">
                    
                    <div className="sec-info">
                      <div className="ico-info">
                        <i className="fa fa-handshake-o" aria-hidden="true"></i>
                      </div>
                    </div>
                    
                    <div className="sec-info">
                      <div className="info-text">
                        <p>En Cambistape trabajamos para brindarle el mejor tipo de cambio para personas como empresas. Asi mismo brindamos soporte para que pueda culminar su proceso sin ningun contratiempo</p>
                        
                      </div>
                    </div>
                    

            </div>
            
          </div>

        </section>
    )
}

export default Home;