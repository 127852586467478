import { useState, useEffect } from 'react'
import Input from '../Input'
import Select from '../Select'

const CuentaBancaria = ({handleCuentaBancaria,visual="normal",selectedcuenta=null}) => {
	const [ contacts,setContacts ] = useState([])
	const [showList, setShowList ] = useState(true)

	useEffect(() => {
		getApiData()
	}, []);


	const [listMoneda] = useState ([
		{id :1 , name:"Soles", simbolo :"S/." },
		{id :2 , name:"Dolar", simbolo : "$"},
		{id :3 , name:"Euro",simbolo : "€" }
	])

	const [listBancos] = useState (
		[
		{id: 1, name: "Banco de Comercio"},
			{id: 2, name: "Banco de Crédito del Perú"},
			{id: 3, name: "Banco Interamericano de Finanzas (BanBif)"},
			{id: 4, name: "Banco Pichincha"},
			{id: 5, name: "BBVA"},
			{id: 6, name: "Citibank Perú"},
			{id: 7, name: "Interbank"},
			{id: 8, name: "MiBanco"},
			{id: 9, name: "Scotiabank Perú"},
			{id: 10, name: "Banco GNB Perú"},
			{id: 11, name: "Banco Falabella"},
			{id: 12, name: "Banco Ripley"},
			{id: 13, name: "Banco Santander Perú"},
			{id: 14, name: "Alfin Banco"},
			{id: 15, name: "Bank of China"},
			{id: 16, name: "Bci Perú"},
			{id: 17, name: "ICBC PERU BANK"}
		])

	const [inputval, setInputval] = useState(
			{
				idbanco :false,
				nombre_cuenta_banco:false,
				cuenta_bancaria :false,
				moneda : false
			}
		)


	const [value, setValue ] = useState(
	{
		id : null,
		idbanco :"",
		nombre_cuenta_banco : "",
		cuenta_bancaria :"",
		cuenta_interbancaria :"",	
		moneda: "",
		default: false
	}
		)


	const handleChangeForm = (e) => {

		if ( ["idbanco","cuenta_bancaria","moneda","nombre_cuenta_banco"].includes(e.target.name) && e.target.value !== ""  ) {
			handlerIsValidated(e.target.name,e.target.value !== "" ? true : false )
		}

		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))

	}

	const handlerIsValidated = (vname,valbool) => {
		setInputval((state) => (
		{
	      ...state,
	      [vname]: valbool
	    }
	    ))			
	}

	const openModalForm = () =>{
		window.$("#modalCuentaBancaria").modal("show")
		if (typeof window.map === "object") {
			setTimeout(() => {
				window.map.invalidateSize()
			}, 1000);
		}
	}


	const habldeeditForm = async (item) => {

		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/user/get/accountBank",{method:'POST',body: JSON.stringify({id:item.id}), headers: myHeaders})
				const data = await response.json()
		       
			if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") ) {
				setValue({
					id : data.data.id,
					idbanco :data.data.id_banco,
					nombre_cuenta_banco : data.data.titular_cuenta,
					cuenta_bancaria : data.data.cuenta_bancaria,
					cuenta_interbancaria :data.data.cuenta_interbancaria,
					moneda: data.data.id_moneda,
					default: data.data.main === 1 ? true : false
				})

				setInputval({
					idbanco : (data.data.id_banco > 0 ) ? true:false ,
					cuenta_bancaria :(data.data.cuenta_bancaria !=="" ) ? true:false ,
					moneda : (data.data.id_moneda > 0 ) ? true:false ,
				})
				window.$("#modalCuentaBancaria").modal("show")
			}
        }

		

	}
	

	const getApiData = async () => {

		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/user/list/accountBank",{method:'POST',body: JSON.stringify({id:window.detailpath}), headers: myHeaders})
				const data = await response.json()
		       
			if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") && data.data.length > 0 ) {
				
				console.log(selectedcuenta,(selectedcuenta !== null) )

				if ( selectedcuenta !== null ) {

					data.data.forEach(function(regis,index){
						if (selectedcuenta.id === regis.id) {
							data.data[index].selected = true;
						}else{
							data.data[index].selected = false;
						}
					});				

				}
				
					setContacts(data.data)
			}
        }
	};

	const submit = async(event) =>{
		event.preventDefault();

		let modaLoading;
		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

			    let apiRequest = window.path_service+"/user/save/accountBank"

				const response = await fetch( apiRequest ,{method:'POST',body:  JSON.stringify(value) , headers: myHeaders})
				const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {
					window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			        });         					

					setValue(	{
						id : null,
						idbanco :"",
						cuenta_bancaria :"",
						nombre_cuenta_banco : "",
						cuenta_interbancaria :"",	
						moneda: "",
						default: false
					})	

					setInputval({
						idbanco :false,
						cuenta_bancaria :false,
						moneda : false
					})
					handleCloseModal()
					getApiData()
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 					
				}
			}

			modaLoading.close();	
	}		

	const handledeleteItem = (item) => {
	  window.Swal.fire({
	    title: 'Estas Seguro de eliminar?',
	    icon: 'warning',
	    showCancelButton: true,
	    cancelButtonColor: "#666",
	    confirmButtonColor: "#13a538",
	    confirmButtonText: 'Si',
	    cancelButtonText: "No"
	  }).then((result) => {
	    if (result.isConfirmed) {
	      deleteItem(item)
	    }
	  })
	}

	const handleSelect = (item) => {
		handleCuentaBancaria(item)
		
		contacts.forEach(function(regis,index){
			if (item.id === regis.id) {
				contacts[index].selected = true;
			}else{
				contacts[index].selected = false;
			}
		});
/*		let regisSelect = contacts.filter(function(reg) {
			return item.id === reg.id
		})
*/
		setContacts(contacts)
	}

	console.log(contacts)


	const deleteItem = async (item) =>{
			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

			    let apiRequest = window.path_service+"/user/delete/accountBank"
				const response = await fetch( apiRequest ,{method:'POST',body:  JSON.stringify({id:item.id}) , headers: myHeaders})
				const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {
					window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			        });         					
					getApiData()
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 					
				}				
			}
	}



	const handleCloseModal = () =>{
		window.$("#modalCuentaBancaria").modal("hide")
	}

	return(
		

            <div className="fm-container-block">

				<div className="filter-result">
				   
				   <ul className="block-list">
					   {
					   	contacts.map((contact,direccionkey) =>     
		            		<li key={contact.id} className={contact.selected ? "fm-block-item active" : "fm-block-item" }>
		            			<input type="radio" className="js-address-item" name="addressitem" readOnly value="on" />
		            			<label className="fm-item-lb">
		            				<span className="fm-item-title">{contact.bank_name}</span>
		            				<span>{contact.currency_name}</span>
		            				<span>TITULAR: {contact.titular_cuenta}</span>
		            				<span>CUENTA BANCARIA: {contact.cuenta_bancaria}</span>    				
		            				<span>CCI: {contact.cuenta_interbancaria}</span>    				
		            				{/*<span className="ms-radio-style">Dirección Activa</span>*/}
		            			</label>
		            			<div className="inter-event">
			            			<a className="btn-delete" onClick={() => handledeleteItem(contact)} >Eliminar</a>
			            			<a className="btn-edit" onClick={() => habldeeditForm(contact)}>Editar</a>
			            			{
			            				visual === "select" ?
			            					<a className="btn-select" onClick={() => handleSelect(contact)}>Elegir</a>
			            				:
			            				null
			            			}
		            			</div>
		            		</li>	
		            		)
			            }
				   </ul>

				  				   
				</div>


            	{
            		showList ?
	            	<div className="fm-block-button">
	            		<button className="btn-add-item" onClick={openModalForm}>Agregar Cuenta Bancaria</button>
	            	</div>
	            	: null
            	}


            	<div className="modal fade" id="modalCuentaBancaria">
            		<div className="modal-dialog" role="document">
            			<div className="modal-content">
            				<div className="modal-header">
            					<h3 className="ms-form-title">
            						<i className="fa fa-money" aria-hidden="true"></i> Cuenta Bancaria
            					</h3>

            					<button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            						<i className="fa fa-times"></i>
            					</button>
            				</div>

            				<div className="modal-body">
            					<form className="form-address" onSubmit={submit}>
            						<div className="ms-wizard-item">
            							<div className="ms-form-payment-register">
									        <Select
										        label = "Bancos*"
										        validated="yes"
										        name="idbanco"
										        value = {value.idbanco}
										        onChange = {handleChangeForm}
									        >			        
										        <option value="">Seleccione Tu banco de Preferencia</option>
										      	{
										      		listBancos.map((entry,enkey) =>
										      			<option key={enkey} value={entry.id}>{entry.name}</option>
										      		)
										      	}
									        </Select>

									      	<Input 
										        label = "Cuenta Bancaria"
										        validated="yes"
										        name="cuenta_bancaria"
										        classinput="cb-form-input"
										        value = {value.cuenta_bancaria}
										        onChange = {handleChangeForm}
									        />

									      	<Input 
										        label = "Titular de Cuenta Bancaria"
										        validated="yes"
										        name="nombre_cuenta_banco"
										        classinput="cb-form-input"
										        value = {value.nombre_cuenta_banco}
										        onChange = {handleChangeForm}
									        />

									        

									      	<Input
										        label = "Cuenta Interbancaria (CCI)"
										        validated="no"
										        name="cuenta_interbancaria"
										        value = {value.cuenta_interbancaria}
										        classinput="cb-form-input"
										        onChange = {handleChangeForm}
									        />


									        <Select
										        label = "Moneda*"
										        validated="yes"
										        name="moneda"
										        classinput="cb-form-input"
										        value = {value.moneda}
										        onChange = {handleChangeForm}

									        >			        
										        <option value="">Seleccione un Tipo de Dirección</option>
										      	{
										      		listMoneda.map((entry,enkey) =>
										      			<option key={enkey} value={entry.id}>{entry.name}</option>
										      		)
										      	}
									        </Select>

									        <div className="ms-wrap-checkbox">
									        	<div className="ms-flex-address">
									        		<input type="checkbox" id="account-bank-main" className="" onChange={handleChangeForm}
									        		name='default' checked={value.default} />
									        		<label htmlFor="account-bank-main">Cuenta Principal</label>
									        	</div>
									        </div>

									        <div className="ms-item ms-full">              
									        { 
									        	( !Object.values(inputval).includes(false) ) ?
									        	<div className="fm-item-form">
									        		<button className="fm-btn-opera">Guardar Cuenta</button>
									        	</div>
									        :
									        	<div className="fm-item-form disabled">
									        		<button disabled className="fm-btn-opera">Guardar Cuenta</button>
									        	</div>		                   
									        }
									        </div>
									    </div>
									</div>
								</form>
							</div>

						</div>
					</div>
				</div>

            </div>	
	)	
}

export default CuentaBancaria;