const InputDni = ({max=8,label,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{

	const validPress = (event) =>{
		if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
	}


	const validInput = (value) =>{
		    if( value.length === parseInt(max)  ) {
	      return true;
	    }else {
	      return false;
	    }
	}

	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
			

		{
				validated==="yes" ?
					(rest.value.length === 0 || validInput(rest.value) === false )?
					<input 
					onKeyPress={validPress}
					className={clasInput+" disabled"} {...rest} id={rest.name} />
					:
					<input 
					onKeyPress={validPress}
					className={clasInput} {...rest} id={rest.name} />
				:
				<input
					onKeyPress={validPress}
				 className={clasInput} {...rest} id={rest.name} />
			}
			
		</div>
	)
}
export default InputDni;