import { useEffect } from 'react'
import DireccionesShipping from './DireccionesShipping'

const PageDireccion = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	useEffect(() => {
		//myListCart();
		window.addEventListener("scroll", listenScrollEvent);
	    return () => {
	        window.removeEventListener("scroll", listenScrollEvent);
	    }	
	}, []);

	const listenScrollEvent = () => {
		if (typeof myLazyLoad !== "undefined") { myLazyLoad.update(); }
	};

	return(
		<div>
		<main  className="wrap-content page-interna">
			<div className="shop-container-register">
				<div id="ms-profile">
				  <div className="ms-section">
				    <div className="ms-tab-profile ms-payment" style={{zIndex: "auto"}}>
				      <div className="ms-lateral-a">
				        <div className="ms-header-menu-profile">
				          <button className="ms-active-menu">Mis Direcciones</button>
				        </div>
				        <div className="ms-body-menu-profile">
				          <div className="ms-list-menu-profile">
				            <div className="ms-header-list-menu">Mi cuenta</div>
				            <ul className="ms-menu-profile">
				              <li>
				                <button className="ms-item"><i className="fa fa-user-o" aria-hidden="true"></i>Mi perfil</button>
				                <ul className="ms-submenu-profile">
		                      <li><a href="/mi-perfil">Mis datos personales</a></li>
		                      <li><a href="/mis-direcciones" className="active">Mis direcciones</a></li>
		                      <li><a href="/cambiar-clave">Cambiar mi clave</a></li>
		                      <li><a href="/mis-facturaciones">Mis datos de facturacion</a></li>
		                      <li><a href="/mis-tarjetas">Mis tarjetas guardadas</a></li>
		                      <li><a href="/metodo_pago/report/user_purchases" Target="_blank">FRESHCLUB</a></li>
				                </ul>
				              </li>
				              <li>
				                <button className="ms-item"><i className="fa fa-truck fa-flip-horizontal" aria-hidden="true"></i>Pedidos</button>
				                <ul className="ms-submenu-profile">
				                  <li><a href="/mis-pedidos">Mis pedidos</a></li>
				                </ul>
				              </li>
				            </ul>
				          </div>
				        </div>
				      </div>
				      
				      <div className="ms-lateral-b page-my-address">
				        <div className="ms-body-menu-profile">
				          <div className="ms-info-menu-selected">
				            <div className="ms-title">Mis direcciones</div>
				            <DireccionesShipping
				            changeMethodShopping = {changeMethodShopping}
				            expressActive = {expressActive}
				            />
				          </div>
				        </div>
				      </div>


				    </div>
				  </div>
				</div>	
			</div>				
		</main>

	    </div>		
	)
}

export default PageDireccion;