const InputName = ({label,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{

	const validPress = (event) =>{
		if (!/[a-zA-ZñÑ ]/.test(event.key)) {
          event.preventDefault();
        }
	}

	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
			

		{
				validated==="yes" ?
					(rest.value.length === 0 )?
					<input 
					onKeyPress={validPress}
					className={clasInput+" disabled"} {...rest} id={rest.name} />
					:
					<input 
					onKeyPress={validPress}
					className={clasInput} {...rest} id={rest.name} />
				:
				<input
					onKeyPress={validPress}
				 className={clasInput} {...rest} id={rest.name} />
			}
			
		</div>
	)
}
export default InputName;