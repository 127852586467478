import { useState, useEffect ,useRef } from 'react'
import Select from './Select'

const Distritos = ({label,validated,getValue,...rest}) =>{
	const [distritos, setDistritos ] = useState([])


	const getApiData = async () => {
	  const response = await fetch(window.path_service+"/api/distritos",{method:'POST'})	  
	  const data = await response.json()
	  if ( data.length > 0 ) {
	  	setDistritos(data)
	  }
	};

	useEffect(() => {
		getApiData()
	}, []);


	return (
		<Select
			label = "Distrito"
			validated="yes"
			label = {label}
			{...rest}
		>
		<option value="">Seleccione un distrito</option>
		{
			distritos.map(distrito =>
				getValue === "tid" ?
					<option key = {distrito.id} value= {distrito.id} >{distrito.name}</option>
				:
					getValue === "name" ?
						<option key = {distrito.tid} value= {distrito.name} >{distrito.name}</option>
					:
						<option key = {distrito.tid} value= {distrito.tid} >{distrito.name}</option>
			)
		}
		</Select>
	)
}

export default Distritos;