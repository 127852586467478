const Pagepreguntasfrecuentes  = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>

            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm">
                                <a className="links-breadcrumb" href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                Preguntas Frecuentes
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-2">Preguntas recuentes</h2>
                        <div className="page-body">
<ol>
    <li>¿Cuál es el procedimiento de cambio?</li>
</ol>
Solo regístrate y/o Ingresa a tu perfil de usuario desde la web o tu app, luego:
<ol>
    <li>Calcula el monto a cambiar e indica tu banco de origen y cuenta destino.</li>
    <li>Transfiere desde tu banca móvil o app de banco a nuestro número de cuenta indicado.</li>
    <li>Coloca tu número de operación de la transferencia bancaria en el campo indicado</li>
</ol>
<ol start="2">
    <li>¿con que bancos trabajan?</li>
</ol>
Por el momento tenemos cuentas en <strong>BCP, BBVA e Interbank.</strong> Para otros bancos realizamos transferencias Interbancarias.
<ol start="3">
    <li>¿en cuanto tiempo tendré mi cambio?</li>
</ol>
En un período aproximado de 30 minutos luego de generar tu operación siempre y cuando tus operaciones sean entre cuentas del BCP, BBVA e Interbank.

Si tus operaciones tienen como destino cuentas en otros bancos, el cambio se procesa dentro de 24 a 48 horas hábiles, después de que recibamos los fondos.
<ol start="4">
    <li>¿Por qué solo me permite seleccionar la cuenta de destino? ¿Cómo debitaran el dinero?</li>
</ol>
Cambistape no tiene acceso a tus cuentas por motivos de tu seguridad. Por ello, tú mismo, a través de tu banca móvil, debes realizar la transferencia de los fondos a nuestras cuentas.
<ol start="5">
    <li>¿hasta que monto puedo cambiar en Cambistape?<br/>
    <strong>No</strong> <strong>hay un monto</strong> <strong>máximo</strong>. Sin embargo, tu banco podría establecer algún tope máximo para transacciones por día.
    </li>
</ol>

<ol start="6">
    <li>¿Hay algún monto mínimo por transacciones?</li>
</ol>
Cuando cambias a través de <strong>BCP, BBVA</strong> e <strong>Interbank</strong> el monto es ilimitado
                        </div>
                    </article>
                </div>
            </main>

        </div>
    )
}

export default Pagepreguntasfrecuentes;
