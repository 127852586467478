const Header = ({user,handleMiproductos,handleSubTotal,expressActive,changeHasExpress,methodShopping,changeMethodShopping,subTotal,miproductos,loadMenu,loadTransversal,transversal,menus,myLazyLoad,list_cart,removeProduct}) =>{
   
   const closeSession = (e) =>{
    localStorage.clear()
    window.location.href = "/";
   }   

return(
    <header id="header">

      <div className="section-full-header">
            {
              (typeof user === "object") && user.hasOwnProperty("email") && user.email !== "" ?
              
              <section className="full-width-banner container">

              <div className="full-header-left">

                 <div className="block-register block-logeed">
                    <a href="#"><i className="fa fa-user-circle" aria-hidden="true"></i><span>Mi cuenta</span></a>
                    <div className="dropdown-menu js-list-menus-login">
                       <div className="container-list-sub-menu">
                          <ul className="lista-menu-login list-sub-menu-desk js-list-sub-menu-desk">
                             <li className="item-menu-login"><a href="/mi-perfil"><i className="fa fa-user-o" aria-hidden="true"></i><span>Mis datos personales</span></a></li>
                             <li className="item-menu-login"><a href="/mis-cuentas"><i className="fa fa-bank" aria-hidden="true"></i><span>Mis Cuentas Bancarias</span></a></li>
                             <li className="item-menu-login"><a href="/cambiar-clave"><i className="fa fa-file-text" aria-hidden="true"></i><span>Cambiar clave</span></a></li>
                             <li className="item-menu-login"><a href="#" onClick={(e)=>closeSession() } ><i className="fa fa-sign-out" aria-hidden="true"></i><span>Cerrar sesión</span></a></li>
                          </ul>
                       </div>
                    </div>
                 </div>
                 <div className="block-login"><a className="js-user-logout" onClick={(e)=>closeSession() }  >CERRAR SESIÓN</a></div>
                 <div className="block-login block-head"><a className="" href="/mis-pedidos"><i className="fa fa-truck" aria-hidden="true"></i><span>MIS OPERACIONES</span></a></div>
                 <div className="block-register js-change-express active"></div>
              </div>      
                
                <div className="full-header-rigth">
                  <div className="group-datos-contact">

                    <div className="telefono">
                      <a href={"https://wa.me/"+global.config.account.cphone} target="_blank">
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        <span>{global.config.account.phone} (solo texto)</span>
                      </a>
                    </div>
                  </div>

                </div>
              </section>

              :
               <section className="full-width-banner container">
                  <div className="full-header-left">
                     <div className="block-register active"><a href="/registro">REGISTRATE</a></div>
                     <div className="block-login"><a href="/iniciar-sesion">INICIAR SESIÓN</a></div>
                     <div className="block-register js-change-express active"></div>
                  </div>

                  <div className="full-header-rigth">
                     <div className="group-datos-contact">
                        
                        <div className="telefono">
                        <a href={"https://wa.me/"+global.config.account.cphone} rel="noopener"><i className="fa fa-whatsapp" aria-hidden="true"></i><span>{global.config.account.phone} (solo texto)</span></a></div>
                     </div>

                     <div className="btn-menu js-btn-open-category js-btn-open-category"><i className="fa fa-bars" aria-hidden="true"></i></div>
                  </div>
               </section>
            }
            

      </div>

       <div className="container section-main">
          <div className="header-item">
             <div className="block-center">
              <a className="logo" href="/">
                <img src="//cambistape.com/images/logo.svg" /></a>
             </div>
          </div>
          <div className="header-item rigth">
            <ul className="list-menu">
              <li className="menu-item"><a href="/quienes-somos" className="title">Quienes somos</a></li>
                <li className="menu-item"><a href="/contacto" cat_id="191" className="title">Contáctanos</a></li>
            </ul>
          </div>
       </div>
    </header>
	)

}

export default Header;