const InputCurrency = ({label,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{

	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
			
			{
				validated==="yes" ?
					rest.value.length === 0 ?
					<input className={clasInput+" disabled"} {...rest} id={rest.name} />
					:
					<input className={clasInput} {...rest} id={rest.name} />
				:
				<input className={clasInput} {...rest} id={rest.name} />
			}
			
		</div>
	)
}

export default InputCurrency