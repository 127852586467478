
import { useState,useEffect } from 'react'
import InputCurrency from '../Input/InputCurrency'
import useStateCurrency from '../useStatePerso/useStateCurrency'

const CambioMoneda = ({getinfoPay,myListCart,nextPage,fechadespacho,pricedespacho,currenmoneda,operation=false}) =>{
	const [montocambiar, setMontocambiar ] = useState(1000)
	const [typechange, setTypechange ] = useState("compra")
	const [montorates, setMontorates ] = useState(currenmoneda)	
	const [userselect, setUserselect ] = useState({})
	const [rates, setRates ] = useState({
		compra:0,
		venta:0
	})


	const [itemxhr, more ] = useStateCurrency({currenmoneda,getinfoPay})

	useEffect(() => {
		getChangeCurrency();
		
	}, []);


    const getChangeCurrency = async() =>{

			var myHeaders = new Headers();
		    myHeaders.append("Content-Type", "application/json");            
		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		    myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
        }

	        const response_service = await fetch(window.path_service+"/api/rates", { method:'POST' , headers: myHeaders})
	        const response = await response_service.json()

            if (response.hasOwnProperty("compra") ) {
            	setRates(response)
            	 montorates.recibo = (montorates.envio * response.compra).toFixed(2)
            	 montorates.rate =  {label:"compra",value: response.compra }
            }

    }

    
	const fc_btn_pay = () =>{
		//getinfoPay(montorates)
		//NEXT SI ESTA DISPONIBLE
		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
			if (montorates.envio > 35) {
				if (operation) {
					nextPage("accountbank")
				}else{
					window.location.href = "/orders/"+itemxhr.token;
				}
				
			}
		}else{
			window.location.href = "/iniciar-sesion";
		}
		
	}

	const handleChangecompra = (e) => {
		const exeRecibo = typechange === "compra" ? (parseFloat(e.target.value)*rates.compra).toFixed(2) : typechange === "venta" ? (parseFloat(e.target.value)/rates.venta).toFixed(2) : null;
		const exerate = typechange === "compra" ? {label:typechange,value: rates.compra } : typechange === "venta" ? {label:typechange,value: rates.venta } : null
		const valor = parseFloat(e.target.value)

		setMontorates({
			"envio": valor,
			"recibo" : exeRecibo,
			"rate" : exerate
		})			

		if(valor > 0 ) {

			more({
			  "envio": e.target.value,
			  "recibo": exeRecibo,
			  "rate": exerate
			})

		}

	}

	const handleChangeventa = (e) => {
		const exeEnvio = typechange === "compra" ? ( parseFloat(e.target.value)/rates.compra).toFixed(2) : typechange === "venta" ? ( parseFloat(e.target.value)*rates.venta).toFixed(2) : null;
		const exerate = typechange === "compra" ? {label:typechange,value: rates.compra } : typechange === "venta" ? {label:typechange,value: rates.venta } : null
		const valor = parseFloat(e.target.value)

		setMontorates({
			"envio": exeEnvio,
			"recibo" : valor,
			"rate" : exerate
		})

		if( valor > 0 ) {

			more({
				"envio": exeEnvio,
				"recibo" : valor,				
				"rate" : exerate
			})

		}

	}

	const cahngeType = (view,event="normal") =>{
		setTypechange(view)
		let fillRates = {}
		if (view === "compra") {
			fillRates = 
			{
				"envio": ( parseFloat(montorates.recibo)/rates.compra).toFixed(2),
				"recibo" : montorates.recibo,
				"rate" : {label:"compra",value: rates.compra }
			}

		}

		if (view === "venta") {
			fillRates = 
				{
				"envio": ( parseFloat(montorates.recibo)*rates.venta).toFixed(2),
				"recibo" : montorates.recibo,
				"rate" : {label:"venta",value: rates.venta }
			}
		}
		
		montorates.envio = fillRates.envio
		montorates.recibo  = fillRates.recibo
		montorates.rate = fillRates.rate

		fillRates.fecha_despacho = fechadespacho;
		fillRates.price_despacho = pricedespacho;

		setMontorates(fillRates)
		more(fillRates)

		if (event === "bullet") {
			//fc_btn_pay()
		}
	}


	return (
		<div className="wrap-change-currency">
			{
				!operation ? 
				<h1 className="title">CAMBIA <span>DÓLARES</span> AL<br/> MEJOR<span> TIPO DE CAMBIO</span></h1>
				:
				null
			}
			

			<div className="wrap-info-currency">
				<div className={typechange === "compra" ? "detail-info-currency active" : "detail-info-currency" }
				onClick={() => cahngeType("compra")} > 
					<span className="info-text">VENDE DÓLARES</span>		
					<span className="info-price">S/ {rates.compra}</span>
				</div>

				<div className={typechange === "venta" ? "detail-info-currency active" : "detail-info-currency" }
					onClick={() => cahngeType("venta")} >
					<span className="info-text">COMPRA DÓLARES</span>		
					<span className="info-price">S/ {rates.venta}</span>
				</div>
			</div>



			<div className="form-currency">
				<div className="wrap-group-currency">
					{
						(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))?
						<InputCurrency
							classWrap ="group-currency"
							label="ENVIAS"
							onChange = {handleChangecompra}
							value={montorates.envio}
							type="number"
							step="10"
							min="50"
							max="10000"							
							className="in-currency"
							 />		
						:
						<InputCurrency
							classWrap ="group-currency"
							label="ENVIAS"
							onChange = {handleChangecompra}
							value={montorates.envio}
							className="in-currency"
							 />						
					}					

						{
							typechange === "venta" ?
							<span className="text-currency">Soles</span>
							:
							<span className="text-currency">Dolar</span>
						}
				</div>

				<div className="wrap-btn-convert">
					{
						typechange === "compra" ?
							<button className="btn btn-primary btn-convert" onClick={() => cahngeType("venta","bullet")} >
								<i className="fa fa-refresh" aria-hidden="true"></i>
							</button>
						:
						null
					}

					{
						typechange === "venta" ?
							<button className="btn btn-primary btn-convert" onClick={() => cahngeType("compra","bullet")} >
								<i className="fa fa-refresh" aria-hidden="true"></i>
							</button>
						:
						null
					}

				</div>

				<div className="wrap-group-currency">
					{
						(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))?
						<InputCurrency
							classWrap ="group-currency"
							label="RECIBES"
							value={montorates.recibo}
							onChange = {handleChangeventa}
							type="number"
							step="10"
							min="50"
							max="10000"
							className="in-currency"
						/>
						:
						<InputCurrency
							classWrap ="group-currency"
							label="RECIBES"
							value={montorates.recibo}
							onChange = {handleChangeventa}
							className="in-currency"
						/>
					}					

						{
							typechange === "compra" ?
							<span className="text-currency">Soles</span>
							:
							<span className="text-currency">Dolar</span>
						}				
				</div>			
			</div>

			<div className="section-bn-currency">				
				<button className="btn btn-estimate" onClick={() => fc_btn_pay()}>Inicia tu operación</button>
			</div>


		</div>
	)
}
export default CambioMoneda	;