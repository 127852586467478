const Direccion =({direccion,handledeleteItem,handleChangeDefault,visual}) =>{
      let ischecked = false
      if (direccion.default ) {
            ischecked = true;
      }

	return(
            		<li key={direccion.id} className="fm-block-item">
            			<input type="radio" className="js-address-item" name="addressitem" id={direccion.id} checked={ischecked} readOnly />
            			<label className="fm-item-lb" htmlFor={direccion.id} 
            			onClick={() => handleChangeDefault(direccion) }
            			 >
            				<span className="fm-item-title">{direccion.addrestype}</span>
            				<span>{direccion.addresscalle} {direccion.addresnumber}</span>
            				<span>{direccion.distritoName}</span>
            				{ direccion.default ? 
            				<span className="ms-radio-style">Dirección Predeterminada</span>
            				:
            				<span className="ms-radio-style">Dirección Activa</span>
            				}
            			</label>
                              { 
                                    !direccion.default ? 
            			         <a href="#" className="ms-delete-addres js-delete-address" onClick={() => handledeleteItem(direccion) } >Eliminar</a>
                                    :
                                    null
                              }
            		</li>		
	)
}

export default Direccion;