import { useEffect } from 'react'
import FormLogin from "./FormLogin";
import Cookies from 'universal-cookie';
import Header from './Header'
import Footer from './Footer'

const PageLogin = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

	useEffect(() => {
    localStorage.clear()
    window.$("body").addClass("page-login")
	}, []);

  return(

<div className="wrapper">
        <div className="logo">
            <img src="-png-logo-0.png" alt="" />
        </div>
        <div className="text-center mt-4 name">
            Iniciar sesión
        </div>
        <FormLogin />

        <div className="text-center fs-6">
            <a href="/forgot-pass">Recuperar Clave?</a> or <a href="/registro">Registro</a>
        </div>
    </div>


  )

}

export default PageLogin;
