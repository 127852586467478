const InputEmail = ({label,span,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{
	
	const validEmail = (value) =>{
	    if(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value) ) {
	      return true;
	    }else {
	      return false;
	    }
	}

	if (rest.value.length === 0 || validEmail(rest.value) === false) {
		classWrap = classWrap +" bad";
	}

	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
			

		{
			span !== "" ?
			<span className={span}></span>
			:null
		}


			{
				validated==="yes" ?
					(rest.value.length === 0 || validEmail(rest.value) === false )?
					<input className={clasInput+" disabled"} {...rest} id={rest.name} />
					:
					<input className={clasInput} {...rest} id={rest.name} />
				:
				<input className={clasInput} {...rest} id={rest.name} />
			}
			
		</div>
	)
}
export default InputEmail;